import {useSelector} from "react-redux";
import React, {useCallback, useEffect, useState} from "react";
import {Link, Redirect} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusSquare, faSyncAlt, faUser, faUserSlash} from "@fortawesome/free-solid-svg-icons";
import Container from "reactstrap/es/Container";
import Helmet from "react-helmet";
import {Card, CardBody, CardHeader, Input} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {reduceQuery0} from "../../api/CoreApi";

export const Dashboard = (props) => {
    const api = useSelector(state => state.api);
    const [redirect, setRedirect] = useState();
    const [mask, setMask] = useState();
    const [persons, setPersons] = useState({
        items: []
        , page: 1
        , sizePerPage: 10
    });

    const onRefreshPersons = useCallback(async (page, sizePerPage) => {
        const from = (page - 1) * sizePerPage;
        const response = await api.auth.requestTokenApiJson({
            request: `/api/person/list`
            , query: {
                from
                , count: sizePerPage
                , mask
            }
        });

        response && setPersons({
                items: response.items.map((item, x) => {
                    return {...item, idx: from + x + 1}
                })
                , totalSize: response.total
                , page
                , sizePerPage
            }
        );
    }, [api.auth, mask]);

    // монтирование
    useEffect(() => {
        onRefreshPersons(1, 10).then();
    }, [props, onRefreshPersons]);

    if (redirect !== undefined) {
        return <Redirect push={true} to={reduceQuery0(redirect.url, redirect.query)}/>;
    }

    const onUpdatePersonActive = async (id, active) => {
        await api.auth.requestTokenApiJson({
            request: `/api/person/update/${id}`
            , json: {
                active
            }
        });
        await onRefreshPersons(persons.page, persons.sizePerPage);
    }

    const columnsPerson = [
        {
            dataField: 'idx'
            , text: '#'
            , style: {
                width: '2rem',
                textAlign: 'center'
            }
        }, {
            dataField: 'name'
            , text: 'Имя пользователя'
            , formatter: (cell, row) => {
                return <Link to={`/v/persons/${row.id}`}>{row.name}</Link>
            }
        }, {
            dataField: '_t'
            , text: ''
            , style: {
                width: '1rem',
                textAlign: 'center'
            }
            , formatter: (cell, row) => {
                return (
                    <div className={'d-flex justify-content-center'}>
                        <div className={'active-item p-2'} onClick={() => onUpdatePersonActive(row.id, !row.active)}>
                            <FontAwesomeIcon icon={row.active ? faUser : faUserSlash} color={row.active ? 'green' : 'red'}/>
                        </div>
                    </div>)
            }
        }
    ];

    const onHandleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        setMask(value === '' ? undefined : value);
    }

    return (
        <Container fluid className={'p-2'}>
            <Helmet>
                <title>[CS24 Visionlabs CAP] - Управление пользователями</title>
            </Helmet>
            <Card>
                <CardHeader className={'d-flex align-items-center'}>
                    <div className={'title mr-auto'}>Список пользователей</div>
                    <div>
                        <Input name={'mask'} id={'mask'} placeholder={'Поиск'} value={mask ?? ''} onChange={onHandleChange}/>
                    </div>
                    <div className={'active-item p-2'} onClick={() => setRedirect(prevState => ({...prevState, 'url': `/v/persons/-`}))}>
                        <FontAwesomeIcon icon={faPlusSquare} color={'green'} title={'Добавить пользователя'}/>
                    </div>
                    <div className={'active-item p-2'} onClick={() => onRefreshPersons(persons.page, persons.sizePerPage)}>
                        <FontAwesomeIcon icon={faSyncAlt} title={'Обновить список'}/>
                    </div>
                </CardHeader>
                <CardBody>
                    <BootstrapTable
                        bootstrap4
                        remote
                        hover
                        condensed
                        keyField="idx"
                        pagination={paginationFactory({
                            page: persons.page,
                            sizePerPage: persons.sizePerPage,
                            totalSize: persons.totalSize,
                            showTotal: false
                        })}
                        onTableChange={(type, {page, sizePerPage}) => onRefreshPersons(page, sizePerPage)}
                        data={persons.items}
                        columns={columnsPerson}
                    />
                </CardBody>
            </Card>
        </Container>
    )
}
