import React, {useCallback, useEffect, useState} from "react";
import {connect} from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Button, Col, Row} from "reactstrap";
import uuid from "uuid/v4";

const Applications = (props) => {
    const [applications, setApplications] = useState([]);

    const onFullRefresh = useCallback(() => {
        const {api} = props;

        api.auth.requestTokenApiJson({
            method: 'POST',
            request: '/api/app/list'
        }).then(response => {
            setApplications(response.apps !== undefined && response.apps.map((i, idx) => {
                return {...i, idx: idx + 1}
            }));
        });
    }, [props]);

    useEffect(() => {
        onFullRefresh();
    }, [props, onFullRefresh]);

    const onSaveApplication = async (id, code) => {
        const {api} = props;
        await api.auth.requestTokenApiJson({
            method: 'POST',
            request: `/api/app/edit/${id}/${code}`
        });

        onFullRefresh();
    };

    const afterSaveCell = (oldValue, newValue, row, column) => {
        if (oldValue !== newValue) {
            onSaveApplication(row.id, row.code);
        }
    };

    const onNewApplication = async () => {
        const {api} = props;

        const request = await api.auth.requestTokenApiJson({
            method: 'POST',
            request: `/api/app/new/${uuid()}`
        });

        onFullRefresh(request.id);
    };

    return (
        <div>
            <Row>
                <Col className={'d-flex justify-content-end my-1'}>
                    <Button color={'success'} onClick={() => onNewApplication()}>Добавить новое приложение</Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <BootstrapTable
                        striped
                        hover
                        keyField="idx"
                        data={applications}
                        columns={[{
                            dataField: 'idx',
                            text: '#',
                            style: {
                                width: '3rem'
                            }
                        }, {
                            dataField: 'code',
                            text: 'Наименование'
                        }]}
                        cellEdit={cellEditFactory({
                            mode: 'click', blurToSave: true, afterSaveCell
                        })}
                    />
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        api: state.api,
        cache: state.cache,
    }
};

export default connect(mapStateToProps)(Applications)
