import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import Moment from "moment";
import cellEditFactory, {Type} from 'react-bootstrap-table2-editor';
import {Button, Col, Row} from "reactstrap";
import AsyncSelect from 'react-select/async';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/fontawesome-free-solid";

const columnsWSDefault = [
    {
        dataField: 'idx',
        text: '#',
        style: {
            width: '3rem'
        }
    }, {
        dataField: 'place',
        text: 'Помещение'
    }, {
        dataField: 'person',
        text: 'ФИО'
    }, {
        dataField: 'name',
        text: 'Компьютер',
        editable: false
    }, {
        dataField: 'ver_cam',
        text: 'vcam',
        editable: false
    }, {
        dataField: 'x_cam',
        text: 'время',
        editable: false,
        formatter: (cell, row) => {
            if (row.x_cam !== undefined) {
                return <span>{Moment(row.x_cam).format('YYYY-MM-DD HH:mm:ss')}</span>
            } else {
                return <span>&nbsp;</span>
            }
        }
    }, {
        dataField: 'ver_ag',
        text: 'vagent',
        editable: false
    }, {
        dataField: 'x_ag',
        text: 'время',
        editable: false,
        formatter: (cell, row) => {
            if (row.x_ag !== undefined) {
                return <span>{Moment(row.x_ag).format('YYYY-MM-DD HH:mm:ss')}</span>
            } else {
                return <span>&nbsp;</span>
            }
        }
    }, {
        dataField: 'reg',
        text: 'Активация',
        editor: {
            type: Type.SELECT,
            options: [{
                value: 1,
                label: 'Активировать'
            }, {
                value: 0,
                label: 'Деактивировать'
            }]
        },
        formatter: (cell, row) => {
            return <span>{row.reg === 1 ? 'Активно' : 'Не активно'}</span>
        }
    }];

export const Workstations = (props) => {
    const api = useSelector(state => state.api);
    const [workstations, setWorkstations] = useState([]);
    const [places, setPlaces] = useState([]);
    const [persons, setPersons] = useState([]);
    const [ctx, setCtx] = useState({});

    const onShowPersons = useCallback((id) => {
        api.auth.requestTokenApiJson({
            request: `/api/ws/persons/${id}`
        }).then(response => {
            setPersons(response.persons && response.persons.map((i, idx) => {
                return {...i, idx: idx + 1, ws_id: id}
            }));

            setCtx({id});
        });
    }, [api.auth]);

    const onShowPlace = useCallback((place) => {
        api.auth.requestTokenApiJson({
            request: `/api/ws/list/${place}`
        }).then(response => {
            setWorkstations(response.workstations.map((i, idx) => {
                return {person: '', place: '', save_sample_descriptor: false, ...i, idx: idx + 1}
            }));

            if (response.workstations.length > 0) {
                onShowPersons(response.workstations[0].id);
            }
        });
    }, [onShowPersons, api.auth]);

    const onFullRefresh = useCallback(() => {
        api.auth.requestTokenApiJson({
            request: '/api/ws/places'
        }).then(response => {
            setPlaces(response.places !== undefined && response.places.map((i, idx) => {
                return {...i, idx: idx + 1}
            }));

            if (response.places.length > 0) {
                onShowPlace(response.places[0].place);
            }
        });
    }, [onShowPlace, api.auth]);

    useEffect(() => {
        onFullRefresh();
    }, [onFullRefresh, props]);

    const onSaveWorkstation = async (id, info) => {
        await api.auth.requestTokenApiJson({
            request: `/api/ws/update/${id}`,
            json: info
        });

        onFullRefresh();
    };

    const afterSaveCell = (oldValue, newValue, row, column) => {
        if (oldValue !== newValue) {
            if (column.dataField === 'place') {
                onSaveWorkstation(row.id, {place: row.place});
            } else if (column.dataField === 'person') {
                onSaveWorkstation(row.id, {person: row.person});
            } else if (column.dataField === 'save_sample_descriptor') {
                onSaveWorkstation(row.id, {save_sample_descriptor: row.save_sample_descriptor === 'true'});
            } else if (column.dataField === 'reg') {
                onSaveWorkstation(row.id, {reg: parseInt(row.reg)});
            }
        }
    };

    const loadOptions = async (inputValue, callback) => {
        const response = await api.auth.requestTokenApiJson({
            request: `/api/person/find/${inputValue}`
        });

        callback(response.persons.map(i => {
            return {value: i.pal_id, label: `${i.name}:${i.app_code}@${i.login}`}
        }));
    };

    const handleChangeSelect = (selectedOption) => {
        setCtx({...ctx, selectedOption});
    };

    const {selectedOption = {}} = ctx;

    const onNewPal = async () => {
        await api.auth.requestTokenApiJson({
            request: `/api/app/link/${ctx.id}/${ctx.selectedOption.value}`
        });

        onShowPersons(ctx.id);
    };

    const onDelete = async (cell, row) => {
        await api.auth.requestTokenApiJson({
            request: `/api/app/dellink/${row.ws_id}/${row.pal_id}`
        });

        onShowPersons(row.ws_id);
    };

    let columnsWS = [...columnsWSDefault];
    if (api.auth.isAcl(['v'])) {
        columnsWS = [...columnsWS, {
            dataField: 'save_sample_descriptor',
            text: 'Поток',
            editor: {
                type: Type.SELECT,
                options: [{
                    value: true,
                    label: 'Активировать'
                }, {
                    value: false,
                    label: 'Деактивировать'
                }]
            },
            formatter: (cell, row) => {
                return <span>{row.save_sample_descriptor ? 'Активно' : 'Не активно'}</span>
            }
        }]
    }

    return (
        <div>
            <Row>
                <Col xs={'auto'}>
                    <BootstrapTable
                        striped
                        hover
                        keyField="idx"
                        data={places}
                        columns={[{
                            dataField: 'idx',
                            text: '#',
                            style: {
                                width: '3rem'
                            }
                        }, {
                            dataField: 'place',
                            text: 'Помещение'
                        }, {
                            dataField: 'count',
                            text: 'Кол-во'
                        }]}
                        selectRow={{
                            hideSelectColumn: true,
                            mode: 'radio',
                            clickToSelect: true,
                            classes: 'selection-row',
                            onSelect: (row, isSelect, rowIndex, e) => {
                                onShowPlace(row.place)
                            }
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={'auto'}>
                    <BootstrapTable
                        striped
                        hover
                        keyField="idx"
                        data={workstations}
                        columns={columnsWS}
                        cellEdit={cellEditFactory({
                            mode: 'dbclick', blurToSave: true, afterSaveCell
                        })}
                        selectRow={{
                            hideSelectColumn: true,
                            mode: 'radio',
                            clickToSelect: true,
                            clickToEdit: true,
                            classes: 'selection-row',
                            onSelect: (row, isSelect, rowIndex, e) => {
                                onShowPersons(row.id)
                            }
                        }}
                    />
                </Col>
                <Col xs={'auto'}>
                    <BootstrapTable
                        striped
                        hover
                        keyField="idx"
                        data={persons}
                        columns={[{
                            dataField: 'idx',
                            text: '#',
                            style: {
                                width: '3rem'
                            }
                        }, {
                            dataField: 'name',
                            text: 'Персона'
                        }, {
                            dataField: 'login',
                            text: 'Логин'
                        }, {
                            dataField: 'code',
                            text: 'Приложение'
                        }, {
                            dataField: 'm-1',
                            text: '',
                            formatter: (cell, row) => {
                                return <>
                                    <FontAwesomeIcon className={'mx-1'} icon={faTrashAlt} onClick={() => onDelete(cell, row)}/>
                                </>
                            }
                        }]}
                    />
                    <AsyncSelect
                        cacheOptions
                        value={selectedOption}
                        loadOptions={loadOptions}
                        onChange={handleChangeSelect}
                        defaultOptions={[]}
                    />
                    <Button color={'success'} disabled={ctx.id === undefined || ctx.selectedOption === undefined} onClick={() => onNewPal()} className={'my-1'}>
                        Допустить логин</Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    {/*<WorkStationCamera id={ctx.id}/>*/}
                </Col>
            </Row>

        </div>);
};
