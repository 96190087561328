import React, {useEffect} from "react";
import ym from 'react-yandex-metrika';
import {useSelector} from "react-redux";

export const withTracker = (WrappedComponent, pageTrack) => {
    const trackPage = (page, yandexMetrika) => {
        if (yandexMetrika !== undefined) {
            ym('hit', page);
        }
    };

    return props => {
        const config = useSelector(state => state.config);
        const yandexMetrika = config && config['yandex-metrika'];

        useEffect(() => trackPage(pageTrack !== undefined ? pageTrack : props.location.pathname, yandexMetrika), [props.location.pathname, yandexMetrika]);

        return <WrappedComponent {...props} />;
    };
};
