import {getRangeDate} from "../components";

export const changeDate = (date) => ({
    type: 'CHANGE_DATE',
    payload: getRangeDate(date, 'day')
});

export const changeRange = (range) => ({
    type: 'CHANGE_RANGE',
    payload: range
});
