import {getRangeDate} from "../components";

const defaultDuration = 'month';

const initialState = {
    date: getRangeDate(new Date(), 'day')
    , duration: defaultDuration
    , detail: '1d'
    , range: {...getRangeDate(new Date(), defaultDuration), period: defaultDuration}
};

export default function app(state = initialState, action) {
    switch (action.type) {
        case 'CHANGE_DATE':
            return {...state, date: action.payload};
        case 'CHANGE_RANGE':
            return {...state, range: {...action.payload}};
        default:
            return state;
    }
}