import React, {Suspense} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {useDispatch} from 'react-redux'

import './App.css';
import {Login, Logout, Base} from "./pages";
import requireAuthentication from "./pages/AuthenticatedComponent";
import {changeConfig} from "./actions/ConfigAction";
import {YMInitializer} from "react-yandex-metrika";
import {withTracker} from "./components";

const VlgApp = () => {
    const dispatch = useDispatch();
    const config = configFetch.read();
    const yandexMetrika = config && config['yandex-metrika'];

    dispatch(changeConfig(config));

    return (
        <Router>
            {yandexMetrika && <YMInitializer accounts={yandexMetrika.ids}/>}
            <Switch>
                <Route exact path="/login" component={withTracker(Login)}/>
                <Route exact path="/logout" component={withTracker(Logout)}/>

                <Route path="/" component={requireAuthentication(Base)}/>
            </Switch>
        </Router>
    );
};

const wrapPromise = (promise) => {
    let status = "pending";
    let result;
    let suspender = promise.then(
        r => {
            status = "success";
            result = r;
        },
        e => {
            status = "error";
            result = e;
        }
    );
    return {
        read() {
            if (status === "pending") {
                throw suspender;
            } else if (status === "error") {
                throw result;
            } else if (status === "success") {
                return result;
            }
        }
    };
};

const configFetch = wrapPromise(
    fetch('/config.json')
        .then(response => response.json())
);

export const App = () => (
    <Suspense fallback="config loading...">
        <VlgApp/>
    </Suspense>
);
