import React from "react";
import {Link} from "react-router-dom";
import {UserInfo} from "./UserInfo";

export const Header = () => {
    return (
        <div className={'app-header px-2 d-flex align-items-center'}>
            <Link className={''} to={'/'}><img className={'logo p-1'} src={process.env.PUBLIC_URL + '/img/logo.png'} alt={''}/></Link>
            <Link to={'/'}><span className={'title ml-3'}>Visionlabs CAP</span></Link>
            <UserInfo className={'ml-auto'}/>
        </div>
    )
};