import Dexie from 'dexie'

class Cache {
    constructor() {
        this.db = new Dexie("vlg");
    }
}

const initialState = {
    db: new Cache()
};

export default function cache(state = initialState) {
    return state;
}
