const {REACT_APP_VLG_API: vlg_api = ''} = process.env;

export const reduceQuery = (query) => Object.keys(query)
    .filter(k => query[k] !== undefined)
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(query[k]))
    .join('&');

export const reduceQuery0 = (uri, query) => {
    if (query !== undefined) {
        const queryParams = reduceQuery(query);
        if (queryParams.length !== 0) {
            return uri + '?' + queryParams;
        }
    }
    return uri;
};

export const requestApi = async (params) => {
    const {api_url = vlg_api, request, method = 'POST', headers, query, json, data, body} = params;
    let body0;

    if (data !== undefined) {
        body0 = data;
    } else if (json !== undefined) {
        body0 = JSON.stringify(json);
    } else {
        body0 = body;
    }

    return await fetch(reduceQuery0(api_url + request, query), {
        method,
        headers,
        body: body0,
        credentials: 'include'
    });
};

export const requestApiJson = async (params) => {
    return await requestApi(params)
        .then(response => response.json());
};

export const uriApi = (uri) => vlg_api + uri;
