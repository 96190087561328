import {AuthApi} from "../api/AuthApi";

const initialState = {
    auth: new AuthApi()
};

export default function api(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_WALLET':
            return {...state, wallet: action.payload};
        case 'CHANGE_FOLDER':
            return {...state, folder: action.payload};
        default:
            return state;
    }
}
