import {combineReducers} from 'redux'
import api from './api'
import app from './app'
import cache from './cache'
import config from './config'

export default combineReducers({
    api,
    app,
    cache,
    config
})