import React from "react";
import {Menu} from "../views/Menu";
import {Redirect, Route, Switch} from "react-router-dom";
import {Dashboard, Footer, Header, OldPersons, PersonEditor, PersonsDashboard, WorkstationPanel, Workstations, WorkstationsDashboard} from "../views";
import Applications from "../views/Applications";
import {Administrators, Company, LogEvents} from "../views/Settings";
import {withTracker} from "../components";

export const Base = () => (
    <div className={'main'}>
        <Header/>
        <div className={'app-body'}>
            <Menu/>
            <Switch>
                <Route path="/dashboard" name="Dashboard" component={withTracker(Dashboard)}/>

                <Route exact path="/v/persons" component={withTracker(PersonsDashboard)}/>
                <Route exact path="/v/persons/:id" component={withTracker(PersonEditor)}/>

                <Route exact path="/v/ws" component={withTracker(WorkstationsDashboard)}/>
                <Route exact path="/v/ws/:id" component={withTracker(WorkstationPanel)}/>

                <Route path="/ws" component={Workstations}/>
                <Route path="/persons" component={OldPersons}/>
                <Route path="/apps" component={withTracker(Applications)}/>

                <Route path="/s/company" component={withTracker(Company)}/>
                <Route path="/s/administrators" component={withTracker(Administrators)}/>
                <Route path="/s/logs" component={withTracker(LogEvents)}/>

                <Redirect from="/" to="/dashboard"/>
            </Switch>
        </div>
        <Footer/>
    </div>
);
