import React, {useCallback, useEffect, useState} from 'react'
import {max, min, map, split, flatten, isEqual} from 'lodash';

export const processValues = (originalValues, delimiter) => isEqual(typeof (originalValues), 'string')
    ? split(originalValues, delimiter).map(v => (Number(v)))
    : flatten([originalValues]);

const fill0 = (fill, i) => fill[i % fill.length];

export const MiniBar = (props) => {
    const [data, setData] = useState();

    const xScale = (input, width, chartValuesLength) => input * (width / (chartValuesLength));

    const yScale = (input, initialHeight, scaleDiff, minValue) => {
        let yScale = initialHeight;

        if (scaleDiff) {
            yScale -= ((input - minValue) / scaleDiff) * initialHeight;
        } else {
            yScale -= 1;
        }

        return yScale;
    }

    const prepareData = useCallback((props) => {
        const {
            maxAxisValue,
            minAxisValue,
            width,
            height,
            padding,
            values,
            delimiter,
        } = props;

        const chartValues = processValues(values, delimiter);
        const maxValue = max([max(chartValues), Number(maxAxisValue)]);
        const minValue = min(chartValues);
        const scaleDiff = maxValue - minValue;

        setData(map(chartValues, (value, i) => {
            const x = xScale(Number(i) + padding, width, chartValues.length);
            const w = xScale(Number(i) + 1 - padding, width, chartValues.length) - x
            const valueY = yScale(value, height, scaleDiff, minValue);
            let y1 = valueY;
            let y2 = valueY;
            let h = 0;

            if (!scaleDiff) {
                h = 1;
            } else if (value < 0) {
                y1 = yScale(min([maxAxisValue, 0]), height, scaleDiff, minValue);
            } else {
                y2 = yScale(max([minAxisValue, 0]), height, scaleDiff, minValue);
            }

            h = y2 - y1;

            if (h === 0) {
                h = 1;
                if (maxAxisValue > 0 && scaleDiff) {
                    y1--;
                }
            }

            return {
                fill: fill0(props.fill, i),
                x,
                y: y1,
                width: w,
                height: h,
            };
        }));
    }, []);

    // монтирование
    useEffect(() => {
        prepareData(props);
    }, [props, prepareData]);

    return (
        <svg className="peity peity-bar" height={props.height} width={props.width}>
            {map(data, (value, index) => (
                <rect
                    key={`${index}${value.x}`}
                    fill={fill0(props.fill, index)}
                    x={value.x}
                    y={value.y}
                    width={value.width}
                    height={value.height}
                />))
            }
        </svg>
    )
}

MiniBar.defaultProps = {
    delimiter: ',',
    fill: ["#4D89F9"],
    minAxisValue: 0,
    padding: 0.1,
    height: 16,
    width: 32,
}