import React, {useCallback, useEffect, useState} from "react";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {uriApi} from "../api/CoreApi";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {PaginationListStandalone, PaginationProvider} from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import 'moment/locale/ru';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import {faCamera, faSlidersH, faSyncAlt, faUserCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Badge, Collapse, FormGroup, Input, Label, Row} from "reactstrap";
import {RangeTimeSelect} from "./DateTimeSelect";

export const WorkStationCamera2 = (props) => {
    const api = useSelector(state => state.api);
    const app = useSelector(state => state.app);
    const [records, setRecords] = useState({
        items: []
        , page: 1
        , sizePerPage: 10
    });
    const [filter, setFilter] = useState({
        active: false
        , value: {
            cam: {
                success: true
                , fail: true
                , noface: true
            }
            , agent: {
                ok: true
                , hide: true
                , block: true
            }
        }
    });

    const toggleFilter = () => setFilter(f => ({...f, active: !f.active}));

    const onRefresh = useCallback(async (page, sizePerPage) => {
        const from = (page - 1) * sizePerPage;
        const response = await api.auth.requestTokenApiJson({
            request: `/api/ws/journal/${props.id}?from=${from}&count=${sizePerPage}`
            , json: {
                from: moment(app.range.from).format()
                , to: moment(app.range.to).format()
                , filter: filter.active ? filter.value : undefined
            }
        });

        response && setRecords({
            items: response.items.map((item, x) => {
                return {...item, idx: from + x + 1}
            })
            , totalSize: response.total
            , page
            , sizePerPage
        });
    }, [props.id, api.auth, app.range, filter.active, filter.value]);

    useEffect(() => {
        if (props.id !== undefined) {
            onRefresh(1, 10);
        }
    }, [onRefresh, props]);

    const columns = [{
        dataField: 'idx',
        text: '#',
        style: {
            width: '1rem'
        }
    }, {
        dataField: 'x',
        text: 'Время',
        style: {
            width: '1rem'
        }
        , formatter: (cell, row) => {
            return <span>{moment(row.dox).locale('ru').format('L LTS.SSS')}</span>
        }
    }, {
        dataField: '_d',
        text: ''
        , style: {
            width: '1rem'
            , textAlign: 'center'
        }
        , formatter: (cell, row) => {
            return <FontAwesomeIcon icon={row.direction === 1 ? faCamera : faUserCheck} color={row.direction === 1 ? 'blue' : 'green'}/>
        }
    }, {
        dataField: '_d2',
        text: 'Запись',
        formatter: (cell, row) => row.direction === 1 ? <CardFace xf={row.xf} persons={props.persons}/> : <CardCheck xf={row.xf}/>
    }];

    const onHandleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name.substring(0, 4) === 'cam.') {
            setFilter(prevValue => ({
                ...prevValue
                , value: {
                    ...prevValue.value
                    , cam: {
                        ...prevValue.value.cam
                        , [name.substring(4)]: value
                    }
                }
            }));
        } else if (name.substring(0, 6) === 'agent.') {
            setFilter(prevValue => ({
                ...prevValue
                , value: {
                    ...prevValue.value
                    , agent: {
                        ...prevValue.value.agent
                        , [name.substring(6)]: value
                    }
                }
            }));
        }
    }

    return (
        <div className={'d-flex flex-column'}>
            <div className={'d-flex justify-content-end align-items-center'}>
                <div className={'active-item p-2'} onClick={toggleFilter}>
                    <FontAwesomeIcon icon={faSlidersH} title={'Фильтр'}/>
                </div>
                <RangeTimeSelect/>
                <div className={'active-item p-2'} onClick={() => {
                    onRefresh(records.page, records.sizePerPage)
                }}>
                    <FontAwesomeIcon icon={faSyncAlt} title={'Обновить'}/>
                </div>
            </div>
            <Collapse isOpen={filter.active}>
                <Row form>
                    <FormGroup className={'m-2'}>
                        <FormGroup check>
                            <Input type="checkbox" id={'cam-success'} name={'cam.success'}
                                   checked={filter.value.cam.success}
                                   onChange={onHandleChange}/>
                            <Label check for={'cam-success'}>Успешное распознавание</Label>
                        </FormGroup>
                        <FormGroup check>
                            <Input type="checkbox" id={'cam-fail'} name={'cam.fail'}
                                   checked={filter.value.cam.fail}
                                   onChange={onHandleChange}/>
                            <Label check for={'cam-fail'}>Отсутствие распознавания</Label>
                        </FormGroup>
                        <FormGroup check>
                            <Input type="checkbox" id={'cam-noface'} name={'cam.noface'}
                                   checked={filter.value.cam.noface}
                                   onChange={onHandleChange}/>
                            <Label check for={'cam-noface'}>Отсутствие лиц</Label>
                        </FormGroup>
                    </FormGroup>
                    <FormGroup className={'m-2'}>
                        <FormGroup check>
                            <Input type="checkbox" id={'agent-ok'} name={'agent.ok'}
                                   checked={filter.value.agent.ok}
                                   onChange={onHandleChange}/>
                            <Label check for={'agent-ok'}>Статус 'ok'</Label>
                        </FormGroup>
                        <FormGroup check>
                            <Input type="checkbox" id={'agent-hide'} name={'agent.hide'}
                                   checked={filter.value.agent.hide}
                                   onChange={onHandleChange}/>
                            <Label check for={'agent-hide'}>Статус 'hide'</Label>
                        </FormGroup>
                        <FormGroup check>
                            <Input type="checkbox" id={'agent-block'} name={'agent.block'}
                                   checked={filter.value.agent.block}
                                   onChange={onHandleChange}/>
                            <Label check for={'agent-block'}>Статус 'block'</Label>
                        </FormGroup>
                    </FormGroup>
                </Row>
            </Collapse>
            <PaginationProvider
                pagination={paginationFactory({page: records.page, sizePerPage: records.sizePerPage, totalSize: records.totalSize, showTotal: false})}
            >
                {
                    ({
                         paginationProps,
                         paginationTableProps
                     }) => (
                        <div>
                            <PaginationListStandalone
                                {...paginationProps}
                            />
                            <BootstrapTable
                                remote
                                striped
                                hover
                                keyField="idx"
                                onTableChange={(type, {page, sizePerPage}) => onRefresh(page, sizePerPage)}
                                data={records.items}
                                columns={columns}
                                {...paginationTableProps}
                            />
                        </div>
                    )
                }
            </PaginationProvider>
        </div>
    );
}

const prepareUri = (src) => `${src.substring(0, 3)}/${src}`

const CardFace = (props) => {
    const {xf} = props;
    const {faces, full_frame, status = {}} = xf;

    const sourceFace = (num, sf) =>
        <div className={'d-flex bg-secondary text-white shadow rounded m-1 px-1'}>
            <div>{num}.</div>
            {sf.warp_name !== undefined
                ? <Zoom zoomMargin={20}>
                    <img alt={``} src={uriApi(`/api/st/warp/${prepareUri(sf.warp_name)}`)} width={60} className={'m-1'}/>
                </Zoom>
                : <></>
            }
            <div className={'d-lex flex-column'}>
                <div>Качество: <span className={'number'}>{sf.score.toFixed(4)}</span></div>
                <div>Площадь: <span className={'number'}>{(sf.rc * 100).toFixed(1)}</span>%</div>
            </div>
        </div>

    const faceInfo = (num, face) => (
        <div key={num} className={'d-flex'}>
            {sourceFace(num, face)}
            {face.x && face.x.map((person, pidx) => {
                const person_info = props.persons && props.persons[person.person_id];
                return (
                    <div key={`${num}.${pidx}`} className={'bg-light shadow rounded m-1 p-1'}>
                        <Badge color={person.find ? 'success' : 'warning'}>{pidx + 1}. {person_info?.name}</Badge>
                        <div className={'d-flex'}>
                            {person.stamps && person.stamps.map((st, sidx) => {
                                const stamp_info = person_info?.descriptors[st.descriptor_id];
                                return (
                                    <div key={`${num}.${pidx}.${sidx}`} className={'d-flex flex-column justify-content-center align-items-center'}>
                                        {stamp_info && <Zoom zoomMargin={20}>
                                            <img alt={``} src={uriApi(`/api/st/warp/${prepareUri(stamp_info.warp_name)}`)} width={60} className={'m-1'}/>
                                        </Zoom>}
                                        {person.find && st.descriptor_id === person.descriptor_id
                                            ? <Badge color={'success'}>{st.similarity.toFixed(5)}</Badge>
                                            : <div className={'number0'}>{st.similarity.toFixed(5)}</div>}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </div>);

    return (
        <div className={classNames('d-flex', 'card-face', {success: status.success && !status.fail, fail: status.fail, noface: status.noface})}>
            <div className={'d-flex mr-auto'}>
                {faces === undefined
                    ? <span>Лица не обнаружены</span>
                    : <>
                        {faces.map((face, idx) => faceInfo(idx + 1, face))}
                    </>}
            </div>
            <div>{full_frame !== undefined
                ? <Zoom zoomMargin={20}>
                    <img alt={``} src={uriApi(`/api/st/warp/${prepareUri(full_frame)}`)} width={60} className={'m-1'}/>
                </Zoom>
                : <></>}</div>
        </div>
    )
}

const CardCheck = (props) => {
    const {xf = {}} = props;
    const {request, response, status} = xf;

    return (
        <div className={classNames('d-flex', 'card-check', {ok: status === 'ok', hide: status === 'hide', block: status === 'block'})}>
            <div className={'d-flex flex-column bg-info text-white rounded shadow m-1 p-1'}>
                <div className={'font-weight-bold'}>Запрос:</div>
                <div>Приложение: <span className={'font-weight-bold text-dark'}>{request.app}</span></div>
                <div>Логин: <span className={'font-weight-bold text-dark'}>{request.login}</span></div>
            </div>
            <div className={'d-flex flex-column bg-light text-dark rounded shadow m-1 p-1'}>
                <div className={'font-weight-bold'}>Ответ:</div>
                <div>Статус: <span className={'font-weight-bold text-primary'}>{response.status}</span></div>
                <div>Качество: <span className={'font-weight-bold text-primary'}>{response._score?.toFixed(4)}</span></div>
                <div>Кол-во заполнений: <span className={'font-weight-bold text-primary'}>{response._fl}</span></div>
                <div>Кол-во пропусков: <span className={'font-weight-bold text-primary'}>{response._sk}</span></div>
                <div>Комментарий: <span className={'font-weight-bold text-primary'}>{response._st}</span></div>
            </div>
            <div className={'d-flex flex-column bg-light text-dark rounded shadow m-1 p-1'}>
                <div className={'font-weight-bold'}>Пакет:</div>
                <pre className={'raw-sample'}>{JSON.stringify(response, null, 2)}</pre>
            </div>
        </div>
    )
}