import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons'
import {faDotCircle, faCalendarAlt} from '@fortawesome/free-regular-svg-icons'
import DayPicker, {DateUtils} from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import Moment from "react-moment";
import moment0 from "moment";
import {extendMoment} from 'moment-range';
import 'moment/locale/ru';
import {changeDate, changeRange} from "../actions/AppActions";
import {Button} from "reactstrap";

const moment = extendMoment(moment0);

const calendarStrings = {
    sameDay: '[Сегодня]',
    nextDay: '[Завтра]',
    lastDay: '[Вчера]',
    nextWeek: function (now) {
        if (now.week() !== this.week()) {
            switch (this.day()) {
                case 0:
                    return '[В следующее] dddd, DD MMM';
                case 1:
                case 2:
                case 4:
                    return '[В следующий] dddd, DD MMM';
                case 3:
                case 5:
                case 6:
                    return '[В следующую] dddd, DD MMM';
                default:
                    return 'L';
            }
        } else {
            if (this.day() === 2) {
                return '[Во] dddd, DD MMM';
            } else {
                return '[В] dddd, DD MMM';
            }
        }
    },
    lastWeek: function (now) {
        if (now.week() !== this.week()) {
            switch (this.day()) {
                case 0:
                    return '[В прошлое] dddd, DD MMM';
                case 1:
                case 2:
                case 4:
                    return '[В прошлый] dddd, DD MMM';
                case 3:
                case 5:
                case 6:
                    return '[В прошлую] dddd, DD MMM';
                default:
                    return 'L';
            }
        } else {
            if (this.day() === 2) {
                return '[Во] dddd, DD MMM';
            } else {
                return '[В] dddd, DD MMM';
            }
        }
    },
    sameElse: 'L',
};

export const DateTimeSelect = () => {
    const dispatch = useDispatch();
    const app = useSelector(state => state.app);
    const [isDayPicker, showDayPicker] = useState(false);

    const onDataSelect = (date) => {
        showDayPicker(false);
        dispatch(changeDate(date));
    };

    return (
        <div className={'active-panel d-flex justify-content-end align-items-center pr-2'}>
            <div className={'active-item px-2 position-relative'}>
                <FontAwesomeIcon icon={faCalendarAlt}/>
                <span className={'pl-1'} onClick={() => showDayPicker(!isDayPicker)}>
                    <Moment locale={'ru'} date={app.date.from} calendar={calendarStrings}/>
                </span>
                <DayPicker showWeekNumbers firstDayOfWeek={1} localeUtils={MomentLocaleUtils} locale={'ru'}
                           className={`rounded shadow dp-panel-right ${isDayPicker ? '' : 'd-none'}`}
                           onDayClick={onDataSelect}
                           todayButton={'Сегодня'}
                           onTodayButtonClick={onDataSelect}
                />
            </div>
            <span className={'active-item px-2'} onClick={() => onDataSelect(moment(app.date.from).subtract(1, 'day'))}><FontAwesomeIcon
                icon={faChevronLeft}/></span>
            <span className={'active-item px-2'} onClick={() => onDataSelect(new Date())}><FontAwesomeIcon icon={faDotCircle}/></span>
            <span className={'active-item px-2'} onClick={() => onDataSelect(moment(app.date.from).add(1, 'day'))}><FontAwesomeIcon icon={faChevronRight}/></span>
        </div>
    )
}

const rangeFormat = (range) => {
    const now = moment();

    const checkSameMonth = (range) => {
        if (moment(range.from).isSame(range.to, 'month')) {
            return moment(range.from).format('DD') + ' - ' + moment(range.to).format('DD MMM');
        } else {
            return moment(range.from).format('DD MMM') + '-' + moment(range.to).format('DD MMM');
        }
    }

    if (range.period === 'month') {
        if (moment(range.from).isSame(now, 'month') && moment(range.to).isSame(now, 'month')) {
            return 'Текущий месяц';
        }
        return checkSameMonth(range);
    } else if (range.period === 'week') {
        if (moment(range.from).isSame(now, 'week') && moment(range.to).isSame(now, 'week')) {
            return 'Текущая неделя';
        }
        return checkSameMonth(range);
    } else if (moment(range.from).isSame(range.to, 'day')) {
        if (moment(range.from).isSame(now, 'day')) {
            return 'Сегодня';
        } else if (moment(range.from).isSame(now.subtract(1, 'day'), 'day')) {
            return 'Вчера';
        } else {
            return moment(range.from).format('DD MMM');
        }
    }
}

export const RangeTimeSelect = () => {
    const dispatch = useDispatch();
    const app = useSelector(state => state.app);
    const [isShowDayPicker, showDayPicker] = useState(false);

    return (
        <div className={'active-panel position-relative d-flex mx-2'}>
            <div className={'active-item p-2'} onClick={() => dispatch(changeRange(subtractRange(app.range)))}>
                <FontAwesomeIcon icon={faChevronLeft}/></div>
            <div className={'active-item p-2'} onClick={() => showDayPicker(prevState => !prevState)}>
                <FontAwesomeIcon icon={faCalendarAlt}/><span className={'pl-1'}>{rangeFormat(app.range)}</span>
            </div>
            <div className={'active-item p-2'} onClick={() => dispatch(changeRange(addRange(app.range)))}>
                <FontAwesomeIcon icon={faChevronRight}/></div>
            <PeriodSelect
                align={'right'}
                range={{...app.range}}
                isVisible={isShowDayPicker}
                onCancel={() => showDayPicker(false)}
                onSelect={(range) => {
                    showDayPicker(false);
                    dispatch(changeRange(range));
                }}
            />
        </div>
    )
}

export const getRange = (date, count, duration, isFormated) => {
    const from = moment(date).startOf(duration);
    const to = moment(from).add(count, duration);
    return isFormated ? {from: from.format(), to: to.format()} : {from, to};
}

export const getRangeDate = (date, duration) => {
    const from = moment(date).startOf(duration);
    const to = moment(date).endOf(duration);
    return {from: from.toDate(), to: to.toDate()};
}

export const addRange = (range, diffReturn = false) => {
    const period = range.period === 'month' ? 'month' : (range.period === 'week' ? 'week' : 'day');
    const diff = moment.range(range.from, range.to).diff(`${period}s`) + 1;
    return {
        from: moment(range.from).add(diff, period).startOf(period).toDate()
        , to: moment(range.to).add(diff, period).endOf(period).toDate()
        , period: range.period
        , diff: diffReturn ? diff : undefined
    }
}

export const subtractRange = (range, diffReturn = false) => {
    const period = range.period === 'month' ? 'month' : (range.period === 'week' ? 'week' : 'day');
    const diff = moment.range(range.from, range.to).diff(`${period}s`) + 1;
    return {
        from: moment(range.from).subtract(diff, period).startOf(period).toDate()
        , to: moment(range.to).subtract(diff, period).endOf(period).toDate()
        , period: range.period
        , diff: diffReturn ? -diff : undefined
    }
}

export const PeriodSelect = (props) => {
    const [range, setRange] = useState({...props.range});
    const modifiers = {start: range.from, end: range.to};

    const onDaySelected = (day) => {
        const r = DateUtils.addDayToRange(day, range);
        setRange(prevState => ({...prevState, ...r}));
    }

    return (
        <div className={`rounded shadow dp-panel-${props.align} ${props.isVisible ? 'd-flex flex-column' : 'd-none'}`}>
            <div className={'d-flex'}>
                <DayPicker
                    showOutsideDays
                    showWeekNumbers
                    numberOfMonths={2}
                    firstDayOfWeek={1}
                    localeUtils={MomentLocaleUtils} locale={'ru'}
                    className={`dp-selectable`}
                    onDayClick={onDaySelected}
                    month={moment().subtract(1, 'month').toDate()}
                    selectedDays={[range.from, {from: range.from, to: range.to}]}
                    modifiers={modifiers}
                />
                <div className={'d-flex flex-column dp-panel-buttons align-items-start'}>
                    <Button className={''} color={'link'} onClick={() => props.onSelect({
                        from: moment().startOf('day').toDate()
                        , to: moment().endOf('day').toDate()
                        , period: 'day'
                    })}>Сегодня</Button>
                    <Button className={''} color={'link'} onClick={() => props.onSelect({
                        from: moment().startOf('day').subtract(1, 'day').toDate()
                        , to: moment().endOf('day').subtract(1, 'day').toDate()
                        , period: 'day'
                    })}>Вчера</Button>
                    <Button className={'mt-2'} color={'link'} onClick={() => props.onSelect({
                        from: moment().startOf('week').toDate()
                        , to: moment().endOf('week').toDate()
                        , period: 'week'
                    })}>Текущая неделя</Button>
                    <Button className={''} color={'link'} onClick={() => props.onSelect({
                        from: moment().startOf('week').subtract(1, 'week').toDate()
                        , to: moment()
                            .endOf('week')
                            .subtract(1, 'week')
                            .endOf('week')
                            .toDate()
                        , period: 'week'
                    })}>Прошлая неделя</Button>
                    <Button className={'mt-2'} color={'link'} onClick={() => props.onSelect({
                        from: moment().startOf('month').toDate()
                        , to: moment().endOf('month').toDate()
                        , period: 'month'
                    })}>Текущий месяц</Button>
                    <Button className={''} color={'link'} onClick={() => props.onSelect({
                        from: moment()
                            .startOf('month')
                            .subtract(1, 'month')
                            .toDate()
                        , to: moment()
                            .endOf('month')
                            .subtract(1, 'month')
                            .endOf('month')
                            .toDate()
                        , period: 'month'
                    })}>Прошлый месяц</Button>
                </div>
            </div>
            <div className={'d-flex justify-content-end'}>
                <Button className={'m-2'} color={'primary'} onClick={() => props.onSelect({
                    from: moment(range.from).startOf('day').toDate()
                    , to: moment(range.to).endOf('day').toDate()
                    , period: 'free'
                })}>Применить</Button>
                <Button className={'m-2'} color={'secondary'} onClick={props.onCancel}>Закрыть</Button>
            </div>
        </div>
    )
}