import {Dashboard} from './Dashboard'
import {OldPersons} from './Persons'
import {Dashboard as PersonsDashboard} from './Person/Dashboard'
import {Info as PersonEditor} from './Person/Info'
import {Header} from './Header'
import {Footer} from './Footer'
import {Workstations} from './Workstations'
import {Dashboard as WorkstationsDashboard} from './Workstation/Dashboard'
import {Info as WorkstationPanel} from './Workstation/Info'

export const getShortTitle = () => 'CS24 Visionlabs CAP';

export {Dashboard, PersonsDashboard, PersonEditor, OldPersons, Header, Footer, Workstations, WorkstationPanel, WorkstationsDashboard}