import {useSelector} from "react-redux";
import React, {useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Container from "reactstrap/es/Container";
import Helmet from "react-helmet";
import {Card, CardBody, CardHeader, Input} from "reactstrap";
import {faSyncAlt, faVideoSlash} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Moment from "moment";
import {MiniBar} from "../../components";

const timerInterval = 5 * 1000; // 5 sec

export const Dashboard = (props) => {
    const api = useSelector(state => state.api);
    const [echo, setEcho] = useState([]);
    const [mask, setMask] = useState();
    const [workstations, setWorkstations] = useState({
        items: []
        , page: 1
        , sizePerPage: 10
    });

    const onRefreshEcho = useCallback(async (ids) => {
        if (ids.length === 0) {
            return;
        }

        const response = await api.auth.requestTokenApiJson({
            request: `/api/ws/echo`
            , json: {
                ids
            }
        });

        if (response !== undefined) {
            let echo0 = {};
            response.data.forEach(w => {
                echo0[w.ws_id] = w.echo.map(e => parseInt(e.score * 10));
            });
            setEcho(echo0);
        }
    }, [api.auth]);

    const onRefreshWorkstations = useCallback(async (page, sizePerPage) => {
        const from = (page - 1) * sizePerPage;
        const response = await api.auth.requestTokenApiJson({
            request: `/api/ws/list0`
            , query: {
                from
                , count: sizePerPage
                , mask
            }
        });

        response && setWorkstations({
                items: response.items.map((item, x) => {
                    return {...item, idx: from + x + 1}
                })
                , totalSize: response.total
                , page
                , sizePerPage
            }
        );
    }, [api.auth, mask]);

    const onUpdateWorkstationActive = async (id, active) => {
        await api.auth.requestTokenApiJson({
            request: `/api/ws/update/${id}`
            , json: {
                reg: active ? 1 : 0
            }
        });
        await onRefreshWorkstations(workstations.page, workstations.sizePerPage);
    };

    const columnsWorkstation = [
        {
            dataField: 'idx'
            , text: '#'
            , style: {
                width: '2rem',
                textAlign: 'center'
            }
        }, {
            dataField: '_echo'
            , text: ''
            , style: {
                width: '2rem',
                textAlign: 'center'
            }
            , formatExtraData: echo
            , formatter: (cell, row, rowIndex, formatExtraData) => {
                return <MiniBar values={formatExtraData && formatExtraData[row.ws_id]} width={60}/>
            }
        }, {
            dataField: 'id'
            , text: 'Id'
            , formatter: (cell, row) => {
                return <Link to={`/v/ws/${row.id}`}>{row.id}</Link>
            }
        }, {
            dataField: 'info.place'
            , text: 'Местоположение'
        }, {
            dataField: 'name'
            , text: 'Имя раб. станции'
            , formatter: (cell, row) => {
                return <Link to={`/v/ws/${row.id}`}>{row.name}</Link>
            }
        }, {
            dataField: 'info.comment'
            , text: 'Описание'
        }, {
            dataField: 'info.ver_cam',
            text: 'vcam',
            editable: false
        }, {
            dataField: 'info.x_cam',
            text: 'время',
            editable: false,
            formatter: (cell, row) => {
                if (row.info.x_cam !== undefined) {
                    return <span>{Moment(row.info.x_cam).format('YYYY-MM-DD HH:mm:ss')}</span>
                } else {
                    return <span>&nbsp;</span>
                }
            }
        }, {
            dataField: 'info.ver_ag',
            text: 'vagent',
            editable: false
        }, {
            dataField: 'info.x_ag',
            text: 'время',
            editable: false,
            formatter: (cell, row) => {
                if (row.info.x_ag !== undefined) {
                    return <span>{Moment(row.info.x_ag).format('YYYY-MM-DD HH:mm:ss')}</span>
                } else {
                    return <span>&nbsp;</span>
                }
            }
        }, {
            dataField: '_t'
            , text: ''
            , style: {
                width: '0.1rem',
                textAlign: 'center'
            }
            , formatter: (cell, row) => {
                return (
                    <div className={'d-flex justify-content-end'}>
                        <div className={'active-item p-2'} onClick={() => onUpdateWorkstationActive(row.id, !row.active)}>
                            <FontAwesomeIcon icon={row.active ? faVideoSlash : faVideoSlash} color={row.active ? 'green' : 'red'}/>
                        </div>
                    </div>)
            }
        }
    ];

    // монтирование
    useEffect(() => {
        onRefreshWorkstations(1, 10).then();
    }, [props, onRefreshWorkstations]);

    useEffect(() => {
        const intervalId = window.setInterval(() => {
            onRefreshEcho(workstations.items.map(m => m.ws_id)).then();
        }, timerInterval);
        return () => clearInterval(intervalId);
    }, [props, onRefreshEcho, workstations.items]);

    const onHandleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        setMask(value === '' ? undefined : value);
    }

    return (
        <Container fluid className={'p-2'}>
            <Helmet>
                <title>[CS24 Visionlabs CAP] - Рабочие места</title>
            </Helmet>
            <Card>
                <CardHeader className={'d-flex align-items-center'}>
                    <div className={'title mr-auto'}>Рабочие места</div>
                    <div>
                        <Input name={'mask'} id={'mask'} placeholder={'Поиск'} value={mask ?? ''} onChange={onHandleChange}/>
                    </div>
                    <div className={'active-item p-2'} onClick={() => onRefreshWorkstations(workstations.page, workstations.sizePerPage)}>
                        <FontAwesomeIcon icon={faSyncAlt} title={'Обновить список'}/>
                    </div>
                </CardHeader>
                <CardBody>
                    <BootstrapTable
                        bootstrap4
                        remote
                        hover
                        condensed
                        keyField="idx"
                        pagination={paginationFactory({
                            page: workstations.page,
                            sizePerPage: workstations.sizePerPage,
                            totalSize: workstations.totalSize,
                            showTotal: false
                        })}
                        onTableChange={(type, {page, sizePerPage}) => onRefreshWorkstations(page, sizePerPage)}
                        data={workstations.items}
                        columns={columnsWorkstation}
                    />
                </CardBody>
            </Card>
        </Container>
    )
}

