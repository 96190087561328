import React, {useCallback, useEffect, useState} from "react";
import {uriApi} from "../api/CoreApi";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, {Type} from "react-bootstrap-table2-editor";
import {Button, Card, CardBody, CardTitle, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import Select from 'react-select';
import Moment from "moment";
import {faLink, faTrashAlt} from '@fortawesome/fontawesome-free-solid'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import {useSelector} from "react-redux";

export const OldPersons = (props) => {
    const api = useSelector(state => state.api);
    const [groups, setGroups] = useState([]);
    const [persons, setPersons] = useState([]);
    const [descriptors, setDescriptors] = useState([]);
    const [logins, setLogins] = useState([]);
    const [apps, setApps] = useState([]);
    const [newLogin, setNewLogin] = useState({});
    const [modalLink, setModalLink] = useState({open: false, id: undefined});
    const [ctx, setCtx] = useState({});

    const onShowPersonInfo = useCallback((id, name) => {
        api.auth.requestTokenApiJson({
            request: `/api/person/descriptors/${id}`
        }).then(request => {
            setDescriptors(request.descriptors && request.descriptors.map((i, idx) => {
                return {...i, idx: idx + 1}
            }));
        });

        api.auth.requestTokenApiJson({
            request: `/api/person/logins/${id}`
        }).then(rl => {
            setLogins(rl.logins && rl.logins.map((i, idx) => {
                return {...i, idx: idx + 1}
            }));
        });

        setNewLogin({id, name});
    }, [api.auth]);

    const onShowGroup = useCallback((active) => {
        api.auth.requestTokenApiJson({
            request: `/api/person/status/${active}`
        }).then(response => {
            setPersons(response.persons !== undefined && response.persons.map((i, idx) => {
                return {...i, idx: idx + 1}
            }));

            if (response.persons.length > 0) {
                const p = response.persons[0];
                onShowPersonInfo(p.id, p.name);
            }
        });
    }, [api.auth, onShowPersonInfo]);

    const onRefreshStatus = useCallback(() => {
        api.auth.requestTokenApiJson({
            request: '/api/person/status'
        }).then(response => {
            setGroups(response.persons !== undefined && response.persons.map((i, idx) => {
                return {...i, idx: idx + 1}
            }));
            if (response.persons.length > 0) {
                onShowGroup(response.persons[0].active);
            }
        });
    }, [api.auth, onShowGroup]);

    useEffect(() => {
        api.auth.requestTokenApiJson({
            request: '/api/app/list'
        }).then(response => {
            setApps(response.apps && response.apps.map(i => {
                    return {value: i.id, label: i.code}
                }
            ))
        });

        onRefreshStatus();
    }, [api.auth, onRefreshStatus]);

    const onSavePerson = async (id, name, active) => {
        await api.auth.requestTokenApiJson({
            request: `/api/person/update/${id}/${name}/${active}`
        });

        onRefreshStatus();
    };

    const afterSaveCell = (oldValue, newValue, row, column) => {
        if (oldValue !== newValue) {
            onSavePerson(row.id, row.name, row.active);
        }
    };

    const handleInputChange = (event) => {
        const target = event.target;
        setNewLogin({...newLogin, login: target.value});
    };

    const handleChangeSelect = (s) => {
        setNewLogin({...newLogin, selected: s});
    };

    const onNewLogin = async () => {
        await api.auth.requestTokenApiJson({
            method: 'POST',
            request: `/api/person/pal/${newLogin.id}/${newLogin.selected.value}/${newLogin.login}`
        });

        onShowPersonInfo(newLogin.id, newLogin.name);
    };

    const isEnableNewLogin = () => newLogin.id !== undefined && newLogin.login !== undefined && newLogin.login.length > 0 && newLogin.selected !== undefined;

    const {login = '', selected = {}} = newLogin;

    const onDelete = async (cell, row) => {
        await api.auth.requestTokenApiJson({
            method: 'POST',
            request: `/api/person/deldesc/${row.id}`
        });

        onRefreshStatus();
    };

    const onLink = (cell, row) => {
        setModalLink({open: true, id: row.id});
    };

    const toggleLink = () => setModalLink({...modalLink, open: !modalLink.open});

    const loadOptions = async (inputValue, callback) => {
        const response = await api.auth.requestTokenApiJson({
            method: 'POST',
            request: `/api/person/find0/${inputValue}`
        });

        callback(response.persons.map(i => {
            return {value: i.id, label: i.name}
        }));
    };

    const handleChangeSelect2 = (selectedOption) => {
        setCtx({...ctx, selectedOption});
    };

    const {selectedOption = {}} = ctx;

    const onDoLink = async () => {
        await api.auth.requestTokenApiJson({
            method: 'POST',
            request: `/api/person/link/${selectedOption.value}/${modalLink.id}`
        });

        toggleLink();

        onRefreshStatus();
    };

    const onDeletePerson = async () => {
        await api.auth.requestTokenApiJson({
            method: 'POST',
            request: `/api/person/delpers/${newLogin.id}`
        });

        onRefreshStatus();
    };

    const onAppendImage = async () => {
        // ---- function definition ----
        const selectFile = (contentType, multiple) => new Promise(resolve => {
            let input = document.createElement('input');
            input.type = 'file';
            input.multiple = multiple;
            input.accept = contentType;

            input.onchange = _ => {
                let files = Array.from(input.files);

                if (multiple)
                    resolve(files);
                else
                    resolve(files[0]);
            };

            input.click();
        });

        let fileDescriptors = await selectFile("image/*", false);
        let dataArray = new FormData();
        dataArray.append('image', fileDescriptors);

        await api.auth.requestTokenApiJson({
            method: 'POST',
            request: `/api/person/descriptor_add/${newLogin.id}`
            , body: dataArray
        });

        onShowPersonInfo(newLogin.id, newLogin.name);
    };

    return (
        <div>
            <Row>
                <Col xs={'auto'}>
                    <BootstrapTable
                        striped
                        hover
                        keyField="idx"
                        data={groups}
                        columns={[{
                            dataField: 'idx',
                            text: '#',
                            style: {
                                width: '3rem'
                            }
                        }, {
                            dataField: 'place',
                            text: 'Статус',
                            formatter: (cell, row) => {
                                return <span>{row.active === true ? 'Активно' : 'Не активно'}</span>
                            }
                        }, {
                            dataField: 'count',
                            text: 'Кол-во'
                        }]}
                        selectRow={{
                            hideSelectColumn: true,
                            mode: 'radio',
                            clickToSelect: true,
                            classes: 'selection-row',
                            onSelect: (row, isSelect, rowIndex, e) => {
                                onShowGroup(row.active)
                            }
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <BootstrapTable
                        striped
                        hover
                        keyField="idx"
                        data={persons}
                        columns={[{
                            dataField: 'idx',
                            text: '#',
                            style: {
                                width: '3rem'
                            }
                        }, {
                            dataField: 'name',
                            text: 'Пользователь'
                        }, {
                            dataField: 'active',
                            text: 'Активация',
                            editor: {
                                type: Type.SELECT,
                                options: [{
                                    value: true,
                                    label: 'Активировать'
                                }, {
                                    value: false,
                                    label: 'Деактивировать'
                                }]
                            },
                            formatter: (cell, row) => {
                                return <span>{row.active ? 'Активно' : 'Не активно'}</span>
                            }
                        }, {
                            dataField: 'doc',
                            text: 'Время',
                            editable: false,
                            formatter: (cell, row) => {
                                return <span>{Moment(new Date(row.doc)).format('YYYY-MM-DD HH:mm:ss')}</span>
                            }
                        }, {
                            dataField: 'cnt_desc',
                            text: 'Кол-во'
                        }]}
                        cellEdit={cellEditFactory({
                            mode: 'dbclick', blurToSave: true, afterSaveCell
                        })}
                        selectRow={{
                            hideSelectColumn: true,
                            mode: 'radio',
                            clickToSelect: true,
                            clickToEdit: true,
                            classes: 'selection-row',
                            onSelect: (row, isSelect, rowIndex, e) => {
                                onShowPersonInfo(row.id, row.name)
                            }
                        }}
                    />
                </Col>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle>Пользователь <b>{newLogin.name}</b></CardTitle>
                            <Row>
                                <Col>
                                    <BootstrapTable
                                        striped
                                        hover
                                        keyField="idx"
                                        data={logins}
                                        columns={[{
                                            dataField: 'idx',
                                            text: '#',
                                            style: {
                                                width: '3rem'
                                            }
                                        }, {
                                            dataField: 'app_code',
                                            text: 'Приложение'
                                        }, {
                                            dataField: 'login',
                                            text: 'Логин'
                                        }]}
                                    />
                                </Col>
                            </Row>
                            <Row className={'my-2'}>
                                <Col xs={4}>
                                    <Select value={selected} options={apps} onChange={handleChangeSelect}/>
                                </Col>
                                <Col xs={4}>
                                    <Input type="text" name="login" onChange={handleInputChange} value={login}/>
                                </Col>
                                <Col xs={4}>
                                    <Button color={'success'} disabled={!isEnableNewLogin()} onClick={() => onNewLogin()}>Добавить логин</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <BootstrapTable
                                        striped
                                        hover
                                        keyField="idx"
                                        data={descriptors}
                                        columns={[{
                                            dataField: 'idx',
                                            text: '#',
                                            style: {
                                                width: '3rem'
                                            }
                                        }, {
                                            dataField: 'warp-img',
                                            text: 'Изображение',
                                            formatter: (cell, row) => {
                                                return <img alt={''} src={uriApi(`/api/st/warp/${row.warp}`)} width={'140'}/>
                                            }
                                        }, {
                                            dataField: 'score',
                                            text: 'Качество'
                                        }, {
                                            dataField: 'm-1',
                                            text: 'Управление',
                                            formatter: (cell, row) => {
                                                return <>
                                                    <FontAwesomeIcon className={'mx-1'} icon={faLink} onClick={() => onLink(cell, row)}/>
                                                    <FontAwesomeIcon className={'mx-1'} icon={faTrashAlt} onClick={() => onDelete(cell, row)}/>
                                                </>
                                            }
                                        }]}
                                    />
                                    <div className={'d-flex'}>
                                        <Button className={'mr-auto'} color={'danger'} disabled={descriptors.length > 0} onClick={() => onDeletePerson()}>Удалить
                                            персону</Button>
                                        <Button color={'primary'} onClick={() => onAppendImage()}>Добавить изображение</Button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Modal isOpen={modalLink.open} toggle={toggleLink}>
                <ModalHeader toggle={toggleLink}>Связать дескриптор</ModalHeader>
                <ModalBody>
                    <Label>Связать с персоной:</Label>
                    <AsyncSelect
                        cacheOptions
                        value={selectedOption}
                        loadOptions={loadOptions}
                        onChange={handleChangeSelect2}
                        defaultOptions={[]}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={onDoLink}>Связать</Button>{' '}
                    <Button color="secondary" onClick={toggleLink}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>);
};

