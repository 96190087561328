import React from 'react';
import {mapToCssModules} from "reactstrap/lib/utils";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

export const MenuPanel = (props) => {
    const {
        className,
        cssModule,
        isVisible = true,
    } = props;

    if (!isVisible) {
        return <></>;
    }

    const classes = mapToCssModules(classNames(
        'menu-panel'
        , 'd-flex'
        , 'flex-column'
        , className
    ), cssModule);

    const getTitle = () =>
        <>
            {props.Icon !== undefined ? <FontAwesomeIcon className={'mr-2'} icon={props.Icon}/> : <></>}
            {props.Title}
        </>;

    return (
        <div className={classes}>
            <h5 className={'px-2 pt-1'}>
                {props.href !== undefined
                    ? <Link to={props.href}>
                        {getTitle()}
                    </Link>
                    : getTitle()
                }
            </h5>
            {props.children}
        </div>
    )
}

export const MenuItem = (props) => {
    const {
        className,
        cssModule,
        isVisible = true,
        color,
        href
    } = props;

    if (!isVisible) {
        return <></>;
    }

    const classes = mapToCssModules(classNames(
        className
        , `bg-${color}`
        , 'menu-item'
    ), cssModule);

    return (
        <Link className={classes} to={href && `${process.env.PUBLIC_URL}${href}`}>
            {props.children}
        </Link>
    )
}