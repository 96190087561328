import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Input, CardFooter, Button, Label, FormGroup, Row, Col, Form, CardTitle, Card, CardBody, CardHeader} from "reactstrap";
import {faEdit, faPlusSquare} from '@fortawesome/free-regular-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import Helmet from "react-helmet";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {getShortTitle} from "../index";

export const Company = (props) => {
    const api = useSelector(state => state.api);
    const [company, setCompany] = useState({
        items: []
        , page: 1
        , sizePerPage: 10
    });
    const [companyRecord, setCompanyRecord] = useState({
        mode: 'none' // none, common
    });

    const onRefresh = useCallback(async (page, sizePerPage) => {
        const from = (page - 1) * sizePerPage;

        const response = await api.auth.requestTokenApiJson({
            request: '/api/dict/company/list'
            , query: {
                from
                , count: sizePerPage
            }
        });
        response && setCompany({
            items: response.items.map((item, x) => {
                return {...item, idx: from + x + 1}
            })
            , totalSize: response.total
            , page
            , sizePerPage
        });
    }, [api.auth]);

    // монтирование
    useEffect(() => {
        onRefresh(1, 10).then(r => {
        });
    }, [props, onRefresh]);

    const columns = [
        {
            dataField: 'idx'
            , text: '#'
            , style: {
                width: '2rem',
                textAlign: 'center'
            }
        }, {
            dataField: 'description'
            , text: 'Компания'
        }, {
            dataField: 'code'
            , text: 'Код'
        }, {
            dataField: 'm1'
            , text: ''
            , style: {
                width: '0.5rem'
            }
            , formatter: (cell, row) => {
                return (
                    <div className={'d-flex'}>
                        <div className={'active-item p-1'} onClick={() => {
                            setCompanyRecord({
                                mode: 'common'
                                , id: row.id
                                , description: row.description
                            })
                        }}><FontAwesomeIcon icon={faEdit} color={'#5b8cff'} title={'Редактировать'}/></div>
                    </div>
                )
            }
        }
    ];

    const onHandleCompanyRecordChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setCompanyRecord(prevValue => ({
            ...prevValue
            , [name]: value
        }));
    }

    const makeCardList = (active) => active
        ? <Card className={`animated fadeIn w-100 rounded shadow`}>
            <CardHeader className={'d-flex align-items-center'}>
                <div className={'title mr-auto'}>Компании</div>
                <div className={'active-item px-2'} onClick={() => setCompanyRecord(prevState => ({mode: 'common'}))}>
                    <FontAwesomeIcon icon={faPlusSquare} color={'green'} title={'Добавить компанию'}/>
                </div>
                <div className={'active-item px-2'} onClick={() => onRefresh(company.page, company.sizePerPage)}>
                    <FontAwesomeIcon icon={faSyncAlt} title={'Обновить список компаний'}/>
                </div>
            </CardHeader>
            <CardBody>
                <BootstrapTable
                    bootstrap4
                    remote
                    hover
                    condensed
                    keyField="idx"
                    pagination={paginationFactory({
                        page: company.page,
                        sizePerPage: company.sizePerPage,
                        totalSize: company.totalSize,
                        showTotal: false
                    })}
                    onTableChange={(type, {page, sizePerPage}) => onRefresh(page, sizePerPage)}
                    data={company.items}
                    columns={columns}
                />
            </CardBody>
        </Card>
        : <></>;

    const makeCardUpdate = (active) => active
        ? <Card className={`animated fadeIn w-100 rounded shadow`}>
            <CardHeader>
                <CardTitle className={'title'}>Изменение данных компании</CardTitle>
            </CardHeader>
            <CardBody>
                <Form>
                    <Row form>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="login">Наименование</Label>
                                <Input type="text" name="description" id="description" placeholder="Наименование компании"
                                       defaultValue={companyRecord.description}
                                       onChange={onHandleCompanyRecordChange}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
            <CardFooter className={'d-flex justify-content-end'}>
                <Button className={'mr-2'} color={'primary'} onClick={async () => {
                    if (companyRecord.id !== undefined) {
                        await api.auth.requestTokenApiJson({
                            request: `/api/dict/company/update/${companyRecord.id}`
                            , json: {
                                description: companyRecord.description !== undefined ? companyRecord.description : ''
                            }
                        });
                    } else {
                        await api.auth.requestTokenApiJson({
                            request: `/api/dict/company/add/`
                            , json: {
                                description: companyRecord.description !== undefined ? companyRecord.description : ''
                            }
                        });
                    }
                    onRefresh(company.page, company.sizePerPage);
                    setCompanyRecord(prevState => ({mode: 'none'}));
                }}>Сохранить</Button>
                <Button color={'secondary'} onClick={() => setCompanyRecord(prevState => ({mode: 'none'}))}>Отмена</Button>
            </CardFooter>
        </Card>
        : <></>;

    return (
        <div className={'w-100 m-1 rounded'}>
            <Helmet>
                <title>[{getShortTitle()}] Управление компаниями</title>
            </Helmet>
            {makeCardList(companyRecord.mode === 'none')}
            {makeCardUpdate(companyRecord.mode === 'common')}
        </div>
    );
}