import {requestApi} from "./CoreApi";

export class AuthApi {
    constructor() {
        const token = localStorage.getItem('token');
        if (token !== null) {
            this.userInfo = JSON.parse(token);
        } else {
            this.userInfo = this.getNullUserInfo();
        }
    }

    getCompanyId = () => this.userInfo.company_id !== undefined ? this.userInfo.company_id : 1;

    getUserId = () => this.userInfo.user_id;

    getNickname = () => this.userInfo.name ?? this.userInfo.login;

    isAcl = (item) => {
        if (item === undefined) {
            return true;
        }

        const {acl = []} = this.userInfo;
        return this.checkAcl(acl, item);
    };

    checkAcl = (acl, item) => {
        for (let l = 0; l < item.length; l++) {
            for (let r = 0; r < acl?.length; r++) {
                if (item[l] === acl[r]) {
                    return true;
                }
            }
        }

        return false;
    }

    getAcl = () => {
        const {acl = []} = this.userInfo;
        return acl;
    };

    getNullUserInfo = () => ({nickname: '', acl: []});

    isUserAuthenticated = () => {
        const {acl = []} = this.userInfo;
        return acl.length > 0;
    };

    login = async (authParams) => {
        const response = await requestApi({
            request: '/api/auth/login'
            , query: {
                login: authParams.login
            }, data: `password=${authParams.password}`
            , headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });

        if (response.status === 200) {
            const userInfo = await response.json();
            this.userInfo = userInfo;
            localStorage.setItem('token', JSON.stringify(userInfo));
            return {auth: true, ...userInfo};
        } else {
            return {auth: false};
        }
    };

    logout = async () => {
        const response = await this.requestTokenApi({
            token: this.userInfo.refresh_token,
            request: '/api/auth/logout'
        });

        const info = await response.json();

        localStorage.removeItem('token');
        this.userInfo = this.getNullUserInfo();

        return {auth: false, ...info};
    };

    requestTokenApiJson = async (param, popup) => {
        return await this.requestTokenApi(param, popup)
            .then(response => response.json());
    };

    requestTokenApi = async (params, popup, safe) => {
        const {access_token, refresh_token, token_type} = this.userInfo;
        const {headers} = params;
        const token = safe ? refresh_token : access_token;

        const response = await requestApi({
            ...params,
            headers: {
                ...headers
                , Authorization: `${token_type} ${token}`
            }
        });

        if (response.status === 200) {
            return response;
        } else if (safe) {
            // все плохо
            return response;
        } else if (response.status === 401) {
            const refresh_response = await this.requestTokenApi({
                request: '/api/auth/refresh'
            }, popup, true);

            if (refresh_response.status === 200) {
                const userInfo = await refresh_response.json();
                const {auth = true} = userInfo;

                if (auth) {
                    this.userInfo = userInfo;
                    localStorage.setItem('token', JSON.stringify(userInfo));
                }

                return await this.requestTokenApi(params, popup, false);
            } else {
                localStorage.removeItem('token');
                this.userInfo = this.getNullUserInfo();
                if (popup !== undefined) {
                    popup();
                }
            }
        }
    };
}