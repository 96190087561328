import React, {useCallback, useEffect, useState} from "react";
import Helmet from "react-helmet";
import {getShortTitle} from "../index";
import {Card, CardHeader, CardBody, Input} from "reactstrap";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import {RangeTimeSelect} from "../../components";
import paginationFactory, {PaginationListStandalone, PaginationProvider} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";

export const LogEvents = (props) => {
    const api = useSelector(state => state.api);
    const app = useSelector(state => state.app);
    const [records, setRecords] = useState({
        items: []
        , page: 1
        , sizePerPage: 10
    });
    const [mask, setMask] = useState();

    const onRefresh = useCallback(async (page, sizePerPage) => {
        const from = (page - 1) * sizePerPage;
        const response = await api.auth.requestTokenApiJson({
            request: `/api/logs/event?from=${from}&count=${sizePerPage}`
            , json: {
                from: moment(app.range.from).format()
                , to: moment(app.range.to).format()
                , mask
            }
        });

        response && setRecords({
            items: response.items.map((item, x) => {
                return {...item, idx: from + x + 1}
            })
            , totalSize: response.total
            , page
            , sizePerPage
        });
    }, [api.auth, app.range.from, app.range.to, mask]);

    useEffect(() => {
        onRefresh(1, 10).then();
    }, [onRefresh]);

    const columns = [{
        dataField: 'idx',
        text: '#',
        style: {
            width: '1rem'
        }
    }, {
        dataField: 'x',
        text: 'Время',
        style: {
            width: '1rem'
        }
        , formatter: (cell, row) => {
            return <span>{moment(row.x).locale('ru').format('L LTS.SSS')}</span>
        }
    }, {
        dataField: 'level'
        , text: 'Уровень'
    }, {
        dataField: 'context'
        , text: 'Контекст'
    }, {
        dataField: 'description'
        , text: 'Описание'
    }];

    const onHandleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        setMask(value === '' ? undefined : value);
    }

    return (
        <div className={'w-100 m-1 rounded'}>
            <Helmet>
                <title>[{getShortTitle()}] </title>
            </Helmet>
            <Card className={`mb-2 w-100 rounded shadow`}>
                <CardHeader className={'d-flex align-items-center'}>
                    <div className={'title mr-auto'}>Журнал событий:</div>
                    <div className={'d-flex justify-content-end align-items-center'}>
                        <div>
                            <Input name={'mask'} id={'mask'} placeholder={'Поиск'} value={mask ?? ''} onChange={onHandleChange}/>
                        </div>
                        <RangeTimeSelect/>
                        <div className={'active-item p-2'} onClick={() => onRefresh(records.page, records.sizePerPage)}>
                            <FontAwesomeIcon icon={faSyncAlt} title={'Обновить'}/>
                        </div>
                    </div>
                </CardHeader>
                <CardBody className={'p-2'}>
                    <PaginationProvider
                        pagination={paginationFactory({page: records.page, sizePerPage: records.sizePerPage, totalSize: records.totalSize, showTotal: false})}
                    >
                        {
                            ({
                                 paginationProps,
                                 paginationTableProps
                             }) => (
                                <div>
                                    <PaginationListStandalone
                                        {...paginationProps}
                                    />
                                    <BootstrapTable
                                        remote
                                        striped
                                        hover
                                        keyField="idx"
                                        onTableChange={(type, {page, sizePerPage}) => onRefresh(page, sizePerPage)}
                                        data={records.items}
                                        columns={columns}
                                        {...paginationTableProps}
                                    />
                                </div>
                            )
                        }
                    </PaginationProvider>
                </CardBody>
            </Card>
        </div>
    );
}
