import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Select from 'react-select';
import {faKey, faSyncAlt, faUserEdit, faUserShield} from "@fortawesome/free-solid-svg-icons";
import {faPlusSquare} from "@fortawesome/free-regular-svg-icons";
import Helmet from "react-helmet";
import {getShortTitle} from "../index";
import {faTimesCircle, faWalking} from "@fortawesome/fontawesome-free-solid";

export const Administrators = (props) => {
    const api = useSelector(state => state.api);
    const [company, setCompany] = useState([]);
    const [currentCompany, setCurrentCompany] = useState({});
    const [users, setUsers] = useState({
        items: []
        , page: 1
        , sizePerPage: 10
    });
    const [userRecord, setUserRecord] = useState({
        mode: 'none' // none, common, pass, acl, bio
    });

    const onRefreshUsers = useCallback(async (company_id, page0, sizePerPage, mask) => {
        const page = page0 > 0 ? page0 : 1;
        const from = (page - 1) * sizePerPage;
        const response = await api.auth.requestTokenApiJson({
            request: `/api/dict/admins/list`
            , query: {
                from
                , count: sizePerPage
                , mask
                , company_id
            }
        });
        response && setUsers({
            items: response.items.map((item, x) => {
                return {...item, idx: from + x + 1}
            })
            , totalSize: response.total
            , page
            , sizePerPage
        });
    }, [api.auth]);

    const onRefreshCompany = useCallback(async () => {
        const response = await api.auth.requestTokenApiJson({
            request: '/api/dict/company/list'
        });

        if (response.items !== undefined) {
            setCompany(response.items.map((i) => ({value: i.id, label: i.description})));

            const fc = response.items.filter((i) => i.id === api.auth.getCompanyId());
            let cc = {};
            if (fc.length > 0) {
                cc = {value: fc[0].id, label: fc[0].description};
            } else if (response.items.length > 0) {
                cc = {value: response.items[0].id, label: response.items[0].description};
            } else {
                return;
            }
            setCurrentCompany(cc);
            onRefreshUsers(cc.value, 1, 10);
        }
    }, [api.auth, onRefreshUsers]);

    // монтирование
    useEffect(() => {
        onRefreshCompany().then();
    }, [props, onRefreshCompany]);

    const onHandleUserRecordChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setUserRecord(prevValue => ({
            ...prevValue
            , [name]: value
        }));
    }

    const accessChangePassword = (id) => id === api.auth.getUserId() || api.auth.isAcl('u')
    const accessChangeAcl = (id) => api.auth.isAcl('u')

    const columns = [{
        dataField: 'idx'
        , text: '#'
        , style: {
            width: '2rem',
            textAlign: 'center'
        }
    }, {
        dataField: 'm-0'
        , text: ''
        , style: {
            width: '0.5rem'
        }
        , formatter: (cell, row) => {
            const acw = api.auth.checkAcl(row.acl, 'c');
            return <FontAwesomeIcon icon={acw ? faWalking : faTimesCircle} color={acw ? 'green' : 'red'}/>
        }
    }, {
        dataField: 'name'
        , text: 'Имя'
    }, {
        dataField: 'login'
        , text: 'Логин'
    }, {
        dataField: 'm-1'
        , text: ''
        , style: {
            width: '0.5rem'
        }
        , formatter: (cell, row) => {
            const acp = accessChangePassword(row.id);
            const aca = accessChangeAcl(row.id);
            return (
                <div className={'d-flex'}>
                    <div className={'active-item p-1'} onClick={() => setUserRecord({mode: 'common', id: row.id, name: row.name, login: row.login})}>
                        <FontAwesomeIcon icon={faUserEdit} color={'#5b8cff'} title={'Редактировать'}/>
                    </div>
                    <div className={`${acp ? 'active-item' : ''} p-1`}
                         onClick={() => acp && setUserRecord({mode: 'pass', id: row.id, name: row.name, login: row.login, password: undefined})}>
                        <FontAwesomeIcon icon={faKey} color={acp ? 'red' : 'grey'} title={'Изменить пароль'}/>
                    </div>
                    <div className={`${aca ? 'active-item' : ''} p-1`} onClick={() => aca && setUserRecord({
                        mode: 'acl', id: row.id, name: row.name, login: row.login, ...row.acl?.reduce((r, a) => ({
                            ...r,
                            [`acl_${a}`]: true
                        }), {})
                    })}>
                        <FontAwesomeIcon icon={faUserShield} color={aca ? 'green' : 'grey'} title={'Права доступа'}/>
                    </div>
                </div>
            )
        }
    }];

    const handleChangeSelect = (s) => {
        setCurrentCompany(s);
        onRefreshUsers(s.value, 1, users.sizePerPage).then();
    };

    const access_A = api.auth.isAcl(['a']);

    const makeCardList = (active) => active
        ? <Card className={`animated fadeIn w-100 rounded shadow`}>
            <CardHeader className={'d-flex align-items-center'}>
                <div className={'title mr-auto'}>Администраторы</div>
                <div className={'cursor-pointer mx-2'} onClick={() => setUserRecord(prevState => ({mode: 'common'}))}>
                    <FontAwesomeIcon icon={faPlusSquare} color={'green'} title={'Добавить пользователя'}/>
                </div>
                <div className={'cursor-pointer mx-1'} onClick={() => onRefreshUsers(currentCompany.value, users.page, users.sizePerPage)}>
                    <FontAwesomeIcon icon={faSyncAlt} title={'Обновить список'}/>
                </div>
            </CardHeader>
            <CardBody className={'p-2'}>
                <BootstrapTable
                    bootstrap4
                    remote
                    hover
                    condensed
                    selectRow={{
                        mode: 'radio' // single row selection
                        , clickToSelect: true
                        , hideSelectColumn: true
                        , bgColor: 'lightgrey'
                    }}
                    keyField="idx"
                    pagination={paginationFactory({
                        page: users.page,
                        sizePerPage: users.sizePerPage,
                        totalSize: users.totalSize,
                        showTotal: false
                    })}
                    onTableChange={(type, {page, sizePerPage}) => onRefreshUsers(currentCompany.value, page, sizePerPage)}
                    data={users.items}
                    columns={columns}
                />
            </CardBody>
        </Card>
        : <></>;

    const makeCardEdit = (active) => active
        ? <Card className={`animated fadeIn w-100 rounded shadow`}>
            <CardHeader>
                <CardTitle className={'title'}>Изменение данных пользователя</CardTitle>
            </CardHeader>
            <CardBody>
                <Form>
                    <Row form>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="login">Логин</Label>
                                <Input type="text" name="login" id="login" placeholder="Логин пользователя"
                                       readOnly={userRecord.id !== undefined}
                                       defaultValue={userRecord.login}
                                       onChange={onHandleUserRecordChange}/>
                            </FormGroup>
                        </Col>
                        <Col md={8}>
                            <FormGroup>
                                <Label for="name">Имя</Label>
                                <Input type="text" name="name" id="name" placeholder="Имя пользователя" defaultValue={userRecord.name}
                                       onChange={onHandleUserRecordChange}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
            <CardFooter className={'d-flex justify-content-end'}>
                <Button className={'mr-2'} color={'primary'} onClick={async () => {
                    if (userRecord.id !== undefined) {
                        await api.auth.requestTokenApiJson({
                            request: `/api/dict/admins/update/${userRecord.id}`
                            , json: {
                                name: userRecord.name !== undefined ? userRecord.name : ''
                            }
                        });
                    } else {
                        await api.auth.requestTokenApiJson({
                            request: `/api/dict/admins/add/`
                            , json: {
                                login: userRecord.login
                                , name: userRecord.name
                            }
                            , query: {
                                company_id: currentCompany.value
                            }
                        });
                    }
                    onRefreshUsers(currentCompany.value, users.page, users.sizePerPage).then();
                    setUserRecord(prevState => ({mode: 'none'}));
                }}>Сохранить</Button>
                <Button color={'secondary'} onClick={() => setUserRecord(prevState => ({mode: 'none'}))}>Отмена</Button>
            </CardFooter>
        </Card>
        : <></>;

    const makeCardPass = (active) => active
        ? <Card className={`animated fadeIn w-100 rounded shadow`}>
            <CardHeader>
                <CardTitle className={'title'}>Изменение пароля для администратора {userRecord.name}</CardTitle>
            </CardHeader>
            <CardBody>
                <Form>
                    <FormGroup>
                        <Label for="passLogin">Логин</Label>
                        <Input type="text" name="login" id="passLogin" placeholder="Логин администратора"
                               defaultValue={userRecord.login}
                               onChange={onHandleUserRecordChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="passPassword">Пароль</Label>
                        <Input type="password" name="password" id="passPassword" placeholder="Пароль администратора"
                               onChange={onHandleUserRecordChange}/>
                    </FormGroup>
                </Form>
            </CardBody>
            <CardFooter className={'d-flex justify-content-end'}>
                <Button className={'mr-2'} color={'primary'} onClick={async () => {
                    if (userRecord.id !== undefined && userRecord.login && userRecord.login.length > 0 && userRecord.password && userRecord.password.length > 0) {
                        await api.auth.requestTokenApiJson({
                            request: `/api/dict/admins/password/${userRecord.id}`
                            , headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            }, data: `login=${userRecord.login}&password=${userRecord.password}`
                        });
                        await onRefreshUsers(currentCompany.value, users.page, users.sizePerPage);
                        setUserRecord(prevState => ({mode: 'none'}));
                    }
                }}>Сохранить пароль</Button>
                <Button color={'secondary'} onClick={() => setUserRecord(prevState => ({mode: 'none'}))}>Отмена</Button>
            </CardFooter>
        </Card>
        : <></>;

    const makeCardAcl = (active) => active
        ? <Card className={`animated fadeIn w-100 rounded shadow`}>
            <CardHeader>
                <CardTitle className={'title'}>Изменение прав доступа для администратора <span className={'text-primary'}>{userRecord.name}</span></CardTitle>
            </CardHeader>
            <CardBody>
                <Form>
                    <FormGroup>
                        <Label for="aclLogin">Логин</Label>
                        <Input type="text" name="login" id="aclLogin" placeholder="Логин пользователя"
                               defaultValue={userRecord.login}
                               readOnly={true}
                        />
                    </FormGroup>
                    <FormGroup check>
                        <Input type="checkbox" id={'aclC'} name={'acl_c'} checked={!!userRecord.acl_c} onChange={onHandleUserRecordChange}/>
                        <Label check for={'aclC'}>Допуск администратора в систему</Label>
                    </FormGroup>
                    <FormGroup check>
                        <Input type="checkbox" id={'aclS'} name={'acl_s'} checked={!!userRecord.acl_s} onChange={onHandleUserRecordChange}/>
                        <Label check for={'aclS'}>Права редактировать пользователей и АРМ</Label>
                    </FormGroup>
                    <FormGroup check>
                        <Input type="checkbox" id={'aclV'} name={'acl_v'} checked={!!userRecord.acl_v} onChange={onHandleUserRecordChange}/>
                        <Label check for={'aclV'} className={'text-danger'}>Права на доступ к расширенной информации биометрии</Label>
                    </FormGroup>
                    <FormGroup check>
                        <Input type="checkbox" id={'aclU'} name={'acl_u'} checked={!!userRecord.acl_u} onChange={onHandleUserRecordChange}/>
                        <Label check for={'aclU'} className={'text-danger'}>Права управления пользователями</Label>
                    </FormGroup>
                    <FormGroup check>
                        <Input type="checkbox" id={'aclA'} name={'acl_a'} checked={!!userRecord.acl_a} onChange={onHandleUserRecordChange}/>
                        <Label check for={'aclA'} className={'text-danger'}>Права для регистрации других компаний</Label>
                    </FormGroup>
                </Form>
            </CardBody>
            <CardFooter className={'d-flex justify-content-end'}>
                <Button className={'mr-2'} color={'primary'} onClick={async () => {
                    if (userRecord.id !== undefined) {
                        const acl = [];
                        Object.keys(userRecord).forEach((k) => {
                            if (k.substr(0, 4) === 'acl_' && !!userRecord[k]) {
                                acl.push(k.substr(4));
                            }
                        });

                        await api.auth.requestTokenApiJson({
                            request: `/api/dict/admins/update/${userRecord.id}`
                            , json: {
                                acl
                            }
                        });
                    }
                    await onRefreshUsers(currentCompany.value, users.page, users.sizePerPage);
                    setUserRecord(prevState => ({mode: 'none'}));
                }}>Сохранить набор прав</Button>
                <Button color={'secondary'} onClick={() => setUserRecord(prevState => ({mode: 'none'}))}>Отмена</Button>
            </CardFooter>
        </Card>
        : <></>;

    return (
        <div className={'w-100 m-1 rounded'}>
            <Helmet>
                <title>[{getShortTitle()}] Управление администраторами</title>
            </Helmet>
            <Card className={`mb-2 w-100 rounded shadow ${access_A ? '' : 'd-none'}`}>
                <CardHeader className={'d-flex align-items-center'}>
                    <div className={'title mr-1'}>Компания:</div>
                    <Select
                        isDisabled={userRecord.mode !== 'none'}
                        className={'w-100'}
                        value={currentCompany}
                        options={company}
                        onChange={handleChangeSelect}/>
                </CardHeader>
            </Card>
            {makeCardList(userRecord.mode === 'none')}
            {makeCardEdit(userRecord.mode === 'common')}
            {makeCardPass(userRecord.mode === 'pass')}
            {makeCardAcl(userRecord.mode === 'acl')}
        </div>
    )
}