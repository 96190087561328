import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import classNames from "classnames";
import {mapToCssModules} from "reactstrap/lib/utils";
import moment from "moment";
import 'moment/locale/ru';
import {Bar} from "react-chartjs-2";
import {RandomRGBA} from "./FormComponents";

export const WorkStationStats = (props) => {
    const api = useSelector(state => state.api);
    const [graph, setGraph] = useState({data: {}});

    const onRefreshStats = useCallback(async (ws_id, range, user) => {
        const options = {
            legend: {
                display: false
            },
            responsive: true,
            /*
                        tooltips: {
                            mode: 'label'
                        },
            */
            elements: {
                line: {
                    fill: false
                }
            },
            scales: {
                xAxes: [
                    {
                        type: 'time'
                        , time: {
                            unit: 'day'
                            , parser: 'L LTS'
                            , minUnit: 'minute'
                        }
                        , offset: true
                        , ticks: {
                            min: moment(range.from).format('L LTS')
                            , max: moment(range.to).format('L LTS')
                        }
                        , display: true
                        , gridLines: {
                            display: false
                        }
                    }
                ],
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-ta',
                        gridLines: {
                            display: true
                        },
                        labels: {
                            show: true
                        },
                        ticks: {
                            beginAtZero: true
                        }
                    }
                ]
            }
        };

        const color = RandomRGBA(120, 0.6);
        const mrf = moment(range.from);
        const mrt = moment(range.to);
        const mrd = moment.range(mrf, mrt).diff('days');

        let detail, type;
        if (mrd < 1) {
            detail = '5m';
            type = 'line';
        } else if (mrd <= 2) {
            detail = '15m';
            type = 'ine';
        } else if (mrd <= 7) {
            detail = '1h';
            type = 'line';
        } else {
            detail = '1d';
            type = 'bar';
        }

        let datasets = [{
            barThickness: undefined /*baseRange.period === 'month' ? 7 :*/
            , data: []
            , label: ''
            , type
            , fill: true
            , lineTension: 0.2
            , borderColor: color
            , backgroundColor: color
            , pointBorderColor: color
            , pointBackgroundColor: color
            , pointHoverBackgroundColor: color
            , pointHoverBorderColor: color
            , yAxisID: 'y-axis-ta'
            , order: 1
        }];

        const response = await api.auth.requestTokenApiJson({
                request: `/api/ws/rep_echo/${ws_id}`
                , json: {
                    from: mrf.format()
                    , to: mrt.format()
                    , detail
                    , user_id: user.id
                }
            })
        ;

        response && response.items.forEach(v => {
            datasets[0].data.push({
                x: moment(v.dox).format('L LTS')
                , y: (v.score).toFixed(3)
            });
        });
//        console.log('==>', baseRange, data, datasets)

        setGraph({
            options
            , data: {
                datasets
            }
        });
    }, [api.auth]);

    // монтирование
    useEffect(() => {
        onRefreshStats(props.workstation, props.range, props.user);
    }, [props, onRefreshStats]);

    const {
        className,
        cssModule
    } = props;

    const classes = mapToCssModules(classNames(
        'w-100'
        , className
    ), cssModule);

    return (
        <div className={classes}>
            <div className={'text-center font-weight-bold'}>{props.user?.name}</div>
            <Bar
                data={graph.data}
                width={props.width ?? 100}
                height={props.height ?? 30}
                options={graph.options}
            />
        </div>
    )
}