import React, {Fragment, useCallback, useEffect, useState} from "react";
import classNames from "classnames";
import Helmet from "react-helmet";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    Table,
    TabPane
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt, faUser, faUsers, faUserSlash} from "@fortawesome/free-solid-svg-icons";
import {faArrowLeft, faEraser, faTrashAlt} from "@fortawesome/fontawesome-free-solid";
import {useSelector} from "react-redux";
import {Link, Redirect} from "react-router-dom";
import {reduceQuery0, uriApi} from "../../api/CoreApi";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import {faEdit, faEye, faSave} from "@fortawesome/fontawesome-free-regular";
import Zoom from "react-medium-image-zoom";

export const Info = (props) => {
    const api = useSelector(state => state.api);
    const [isCanEdit, setCanEdit] = useState(false);
    const [redirect, setRedirect] = useState();
    const [stat0, setStat0] = useState([]);
    const [descriptors, setDescriptors] = useState([]);
    const [selectedApp, setSelectedApp] = useState({});
    const [currentPerson, setCurrentPerson] = useState({
        id: undefined
    });
    const [logins, setLogins] = useState([]);
    const [activeTab, setActiveTab] = useState('descriptors');

    const onRefreshStats = useCallback(async (id) => {
        const response = await api.auth.requestTokenApiJson({
            request: `/api/person/stat0/${id}`
        });
        response && setStat0(response.data);
    }, [api.auth]);

    const onRefreshLogins = useCallback(async (id) => {
        const response = await api.auth.requestTokenApiJson({
            request: `/api/person/logins/${id}`
        });
        response && setLogins(response.logins);
    }, [api.auth]);

    const onRefreshDescriptors = useCallback(async (id) => {
        const response = await api.auth.requestTokenApiJson({
            request: `/api/person/descriptors/${id}`
        });
        response && setDescriptors(response.descriptors.map((i, idx) => {
            return {...i, idx: idx + 1}
        }));
    }, [api.auth]);

    const onRefreshPersonInfo = useCallback(async (id) => {
        const response = await api.auth.requestTokenApiJson({
            request: `/api/person/info/${id}`
        });
        response && setCurrentPerson(response.data);
    }, [api.auth]);

    // монтирование
    useEffect(() => {
        const {id} = props.match.params;
        if (id === '-') {
            setCurrentPerson({
                id: undefined
            });
            setCanEdit(true);
        } else {
            onRefreshPersonInfo(id);
            onRefreshLogins(id);
            onRefreshDescriptors(id);
            onRefreshStats(id);
        }
    }, [props, onRefreshLogins, onRefreshPersonInfo, onRefreshDescriptors, onRefreshStats]);

    if (redirect !== undefined) {
        return <Redirect push={true} to={reduceQuery0(redirect.url, redirect.query)}/>;
    }

    const toggle = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    const loadOptionsApps = (inputValue) =>
        new Promise((resolve) => {
            api.auth.requestTokenApiJson({
                request: `/api/app/list/${inputValue}`
            }).then((response) => {
                resolve(response.apps.map((i) => ({value: i.id, label: i.code})));
            });
        });

    const onHandleAppChange = (selectedOption) => {
        setSelectedApp(prevState => ({
            ...prevState
            , id: selectedOption !== null ? selectedOption.value : undefined
            , code: selectedOption !== null ? selectedOption.label : undefined
        }));
    };

    const onHandleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        /*
                if (isCanEdit) {
                    if (name.substring(0, 5) === 'info.') {
                        setCurrentWorkstation(prevValue => ({
                            ...prevValue
                            , info: {
                                ...prevValue.info
                                , [name.substring(5)]: value
                            }
                        }));
                    } else {
                    }
                }
        */
        setCurrentPerson(prevValue => ({
            ...prevValue
            , [name]: value
        }));
    }

    const classesInputLeft = classNames('mb-1', {'border-primary': isCanEdit});

    const prepareNextDescriptor = (idx, info) => {
        let rows = [];
        for (let k = 1; k < info.length; k++) {
            rows.push(
                <tr key={idx + '.' + k}>
                    <td>
                        <Zoom zoomMargin={20}>
                            <img alt={`${info[k].descriptor}`} src={uriApi(`/api/st/warp/${info[k].warp}`)} width={70} className={'mx-1'}
                                 title={`${info[k].descriptor}`}/>
                        </Zoom>
                    </td>
                    <td>{info[k].count}</td>
                    <td>{info[k].avg.toFixed(6)}</td>
                </tr>
            );
        }
        return rows;
    };

    const onDeleteDescriptor = async (id) => {
        await api.auth.requestTokenApiJson({
            request: `/api/person/deldesc/${id}`
        });

        onRefreshDescriptors(currentPerson.id);
    };

    const onAppendDescriptor = async () => {
        // ---- function definition ----
        const selectFile = (contentType, multiple) => new Promise(resolve => {
            let input = document.createElement('input');
            input.type = 'file';
            input.multiple = multiple;
            input.accept = contentType;

            input.onchange = _ => {
                let files = Array.from(input.files);

                if (multiple)
                    resolve(files);
                else
                    resolve(files[0]);
            };

            input.click();
        });

        let fileDescriptors = await selectFile("image/*", false);
        let dataArray = new FormData();
        dataArray.append('image', fileDescriptors);

        await api.auth.requestTokenApiJson({
            request: `/api/person/descriptor_add/${currentPerson.id}`
            , body: dataArray
        });

        onRefreshDescriptors(currentPerson.id);
    };

    const onCancel = () => {
        setCanEdit(false);

        if (currentPerson.id !== undefined) {
            onRefreshPersonInfo(currentPerson.id);
        } else {
            setRedirect(prevState => ({...prevState, 'url': `/v/persons`}));
        }
    }

    const isFormValid = () => (currentPerson.name ?? '') !== '';

    const onSaveInfo = async () => {
        if (isFormValid()) {
            const response = await api.auth.requestTokenApiJson({
                request: `/api/person/${currentPerson.id !== undefined ? ('update/' + currentPerson.id) : 'add'}`
                , json: {
                    name: currentPerson.name
                }
            });

            setCanEdit(false);
            setCurrentPerson(response.data);
        }
    }

    const onUpdatePersonActive = async (active) => {
        await api.auth.requestTokenApiJson({
            request: `/api/person/update/${currentPerson.id}`
            , json: {
                active
            }
        });
        onRefreshPersonInfo(currentPerson.id);
    }

    return (
        <Container fluid className={'p-2'}>
            <Helmet>
                <title>[CS24 Visionlabs CAP] - Управление пользователями</title>
            </Helmet>
            <Card className={'border rounded shadow'}>
                <CardHeader className={'d-flex align-items-center'}>
                    <div className={'active-item p-2'} onClick={() => setRedirect(prevState => ({...prevState, 'url': `/v/persons`}))}>
                        <FontAwesomeIcon icon={faArrowLeft} title={'Вернуться к списку пользователей'}/>
                    </div>
                    <div className={'title mr-auto'}>Данные пользователя</div>
                    <div className={'active-item p-2'}><FontAwesomeIcon icon={faEraser} color={'red'}/>
                    </div>
                    <div className={'active-item p-2'} onClick={() => onUpdatePersonActive(!currentPerson.active)}>
                        <FontAwesomeIcon icon={currentPerson.active ? faUser : faUserSlash} color={currentPerson.active ? 'green' : 'red'}/>
                    </div>
                    <div className={'active-item p-2'} onClick={() => {
                        isCanEdit ? onSaveInfo() : setCanEdit(prevState => !prevState)
                    }}><FontAwesomeIcon icon={isCanEdit ? faSave : faEdit} color={'blue'}/>
                    </div>
                    <div className={'active-item p-2'} onClick={() => {
                        onRefreshPersonInfo(currentPerson.id);
                        onRefreshLogins(currentPerson.id);
                    }}>
                        <FontAwesomeIcon icon={faSyncAlt} title={'Обновить'}/>
                    </div>
                </CardHeader>
                <CardBody>
                    <Form>
                        <Row>
                            <Col xs={6}>
                                <Row className={'d-flex align-items-center'}>
                                    <Col xs={4} className={'d-flex justify-content-end'}>
                                        <Label for={'id'}>Идентификатор:</Label>
                                    </Col>
                                    <Col xs={8}>
                                        <Input name={'id'} id={'id'} placeholder={'Идентификатор'} value={currentPerson.id ?? ''} onChange={onHandleChange}
                                               readOnly className={classesInputLeft}
                                        />
                                    </Col>
                                </Row>
                                <Row className={'d-flex align-items-center'}>
                                    <Col xs={4} className={'d-flex justify-content-end'}>
                                        <Label for={'name'}>Имя пользователя:</Label>
                                    </Col>
                                    <Col xs={8}>
                                        <Input name={'name'} id={'name'} placeholder={'Имя пользователя'} value={currentPerson.name ?? ''}
                                               onChange={onHandleChange} readOnly={!isCanEdit} className={classesInputLeft}/>
                                    </Col>
                                </Row>
                                <Row className={`align-items-center ${isCanEdit ? 'd-flex' : 'd-none'}`}>
                                    <Col xs={12} className={'d-flex justify-content-end'}>
                                        <Button className={'mx-1'} color={'primary'} disabled={!isFormValid()}
                                                onClick={() => onSaveInfo()}>Сохранить</Button>
                                        <Button className={'mx-1'} color={'secondary'} onClick={() => onCancel()}>Отменить</Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={6}>
                            </Col>
                        </Row>
                    </Form>
                    {currentPerson.id !== undefined
                        ? <>
                            <Nav tabs className={'nav-bar mt-2'}>
                                <NavItem>
                                    <NavLink className={classNames('cursor-pointer', {active: activeTab === 'logins'})} onClick={() => {
                                        toggle('logins');
                                    }}>
                                        <FontAwesomeIcon className={'mr-1'} icon={faUsers} color={'green'}/>Логины пользователя
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classNames('cursor-pointer', {active: activeTab === 'descriptors'})} onClick={() => {
                                        toggle('descriptors');
                                    }}>
                                        <FontAwesomeIcon className={'mr-1'} icon={faEye} color={'red'}/>Дескрипторы
                                    </NavLink>
                                </NavItem>
{/*                                <NavItem>
                                    <NavLink className={classNames('cursor-pointer', {active: activeTab === 'stats'})} onClick={() => {
                                        toggle('stats');
                                    }}>
                                        <FontAwesomeIcon className={'mr-1'} icon={faChartPie} color={'blue'}/>Статистика использоваения
                                    </NavLink>
                                </NavItem>*/}
                            </Nav>
                            <TabContent activeTab={activeTab} className={'mt-2'}>
                                <TabPane tabId={'logins'}>
                                    <Row>
                                        <Col xs={8}>
                                            <Table>
                                                <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Приложение</th>
                                                    <th>Логин</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <AsyncSelect
                                                            cacheOptions
                                                            defaultOptions
                                                            value={{value: selectedApp.id, label: selectedApp.code}}
                                                            loadOptions={loadOptionsApps}
                                                            onChange={onHandleAppChange}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Input name={'link_login'} id={'link_login'} placeholder={'Логин'}
                                                               value={currentPerson?.link_login ?? ''}
                                                               onChange={onHandleChange}/>
                                                    </td>
                                                    <td>
                                                        <Button className={'my-1 w-100'} color={'primary'}
                                                                disabled={currentPerson.id === undefined || selectedApp.id === undefined || currentPerson.link_login === undefined}
                                                                onClick={async () => {
                                                                    await api.auth.requestTokenApiJson({
                                                                        request: `/api/person/pal/${currentPerson.id}/${selectedApp.id}/${currentPerson.link_login}`
                                                                    });
                                                                    onRefreshLogins(currentPerson.id);
                                                                }}
                                                        >Добавить логин</Button>
                                                    </td>
                                                </tr>
                                                {logins && logins.map((p, idx) => (
                                                    <tr key={idx}>
                                                        <th>{idx + 1}.</th>
                                                        <td>{p.app_code}</td>
                                                        <td>{p.login}</td>
                                                        <td>
                                                            {/*
                                                    <div className={'active-item p-2'} onClick={async () => {
                                                        await api.auth.requestTokenApiJson({
                                                            request: `/api/app/dellink/${currentWorkstation.id}/${p.pal_id}`
                                                        });
                                                        onRefreshPersons(currentWorkstation.id);
                                                    }}>
                                                        <FontAwesomeIcon icon={faTrashAlt}/>
                                                    </div>
*/}
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId={'descriptors'}>
                                    <Row>
                                        <Col xs={6}>
                                            <Table>
                                                <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Изображение</th>
                                                    <th>Качество</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {descriptors && descriptors.map((d, idx) => (
                                                    <tr key={idx}>
                                                        <th>{idx + 1}.</th>
                                                        <td>
                                                            <Zoom zoomMargin={20}>
                                                                <img alt='' src={uriApi(`/api/st/warp/${d.warp}`)} width={100} className={'mx-1'}/>
                                                            </Zoom>
                                                        </td>
                                                        <td>{d.score.toFixed(6)}</td>
                                                        <td>
                                                            <div className={'active-item p-2'} onClick={() => onDeleteDescriptor(d.id)}>
                                                                <FontAwesomeIcon className={'mx-1'} icon={faTrashAlt}/>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </Col>
                                        <Col xs={6}>
                                            <Button color={'primary'} onClick={() => onAppendDescriptor()}>Добавить изображение</Button>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId={'stats'}>
                                    <Table>
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Рабочая станция</th>
                                            <th>Дескриптор</th>
                                            <th>Кол-во</th>
                                            <th>Ср. похожесть</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {stat0 && stat0.map((s, idx) =>
                                            <Fragment key={idx}>
                                                <tr>
                                                    <th rowSpan={s.info.length}>{idx + 1}.</th>
                                                    <td rowSpan={s.info.length}><Link to={`/v/ws/${s.id}`}>{s.name}</Link></td>
                                                    <td>
                                                        <Zoom zoomMargin={20}>
                                                            <img alt={`${s.info[0].descriptor}`} src={uriApi(`/api/st/warp/${s.info[0].warp}`)} width={70}
                                                                 className={'mx-1'} title={`${s.info[0].descriptor}`}/>
                                                        </Zoom>
                                                    </td>
                                                    <td>{s.info[0].count}</td>
                                                    <td>{s.info[0].avg.toFixed(6)}</td>
                                                </tr>
                                                {prepareNextDescriptor(idx, s.info)}
                                            </Fragment>
                                        )}
                                        </tbody>
                                    </Table>
                                </TabPane>
                            </TabContent>
                        </>
                        : <></>
                    }
                </CardBody>
            </Card>
        </Container>
    )
}