import React, {useState} from "react";
import {mapToCssModules} from "reactstrap/lib/utils";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {Button, Popover, PopoverBody} from "reactstrap";
import {Redirect} from "react-router-dom";
import {reduceQuery0} from "../api/CoreApi";

export const UserInfo = (props) => {
    const api = useSelector(state => state.api);
    const [redirect, setRedirect] = useState();
    const [popoverOpen, setPopoverOpen] = useState(false);

    const toggle = () => setPopoverOpen(!popoverOpen);

    const {
        className,
        cssModule
    } = props;

    const classes = mapToCssModules(classNames(
        'cursor-pointer'
        , 'd-flex'
        , 'flex-column'
        , className
    ), cssModule);

    if (redirect !== undefined) {
        return <Redirect push={true} to={reduceQuery0(redirect.url, redirect.query)}/>;
    }

    return (
        <div className={classes} id={'Popover1'} onClick={() => {
            setPopoverOpen(true);
            setTimeout(
                () => {
                    setPopoverOpen(false);
                }, 5000)
        }}>
            {api.auth.getNickname()}
            <Popover placement="bottom" isOpen={popoverOpen} target="Popover1" toggle={toggle}>
                <PopoverBody><Button color={'link'} onClick={async () => {
                    await api.auth.logout();
                    setRedirect(prevState => ({...prevState, 'url': `/`}));
                }}>Выход</Button></PopoverBody>
            </Popover>
        </div>
    )
}