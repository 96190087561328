import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import classNames from "classnames";
import Helmet from "react-helmet";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    Table,
    TabPane
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboardList, faSyncAlt, faUsers} from "@fortawesome/free-solid-svg-icons";
import {Link, Redirect} from "react-router-dom";
import {faArrowLeft, faTrashAlt} from "@fortawesome/fontawesome-free-solid";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import {faEdit, faSave} from "@fortawesome/fontawesome-free-regular";
import {reduceQuery0} from "../../api/CoreApi";
import {RangeTimeSelect, WorkStationCamera2, WorkStationStats} from "../../components";
import Moment from "moment";

export const Info = (props) => {
    const api = useSelector(state => state.api);
    const app = useSelector(state => state.app);
    const [redirect, setRedirect] = useState();
    const [activeTab, setActiveTab] = useState('person');
    const [activeTabHeader, setActiveTabHeader] = useState('protocol');
    const [isCanEdit, setCanEdit] = useState(false);
    const [selectedPAL, setSelectedPAL] = useState({});
    const [currentWorkstation, setCurrentWorkstation] = useState({
        id: undefined
    });
    const [personLogins, setPersonLogins] = useState([]);
    const [personDescriptors, setPersonDescriptors] = useState([]);

    const onRefreshPersonDescriptors = useCallback(async (id) => {
        const response = await api.auth.requestTokenApiJson({
            request: `/api/ws/descriptors/${id}`
        });
        response && setPersonDescriptors(response.data);
    }, [api.auth]);

    const onRefreshPersons = useCallback(async (id) => {
        const response = await api.auth.requestTokenApiJson({
            request: `/api/ws/persons/${id}`
        });
        response && setPersonLogins(response.persons);
    }, [api.auth]);

    const onRefreshWorkstationInfo = useCallback(async (id) => {
        const response = await api.auth.requestTokenApiJson({
            request: `/api/ws/info/${id}`
        });

        const {agent = {}, cam = {}, similarity = 0.7, ...rest} = response.data?.info;

        response && setCurrentWorkstation({
            ...response.data
            , info: {
                ...rest
                , similarity: parseFloat((similarity).toFixed(2))
                , agent_interval: agent.interval
                , agent_mode: agent.mode
                , cam_interval: cam.interval
                , cam_index: cam.index
                , cam_mode: cam.mode
                , cam_width: cam.width
                , cam_height: cam.height
            }
        });
    }, [api.auth]);

    // монтирование
    useEffect(() => {
        const {id} = props.match.params;
        onRefreshWorkstationInfo(id);
        onRefreshPersons(id);
        onRefreshPersonDescriptors(id);
    }, [props, onRefreshWorkstationInfo, onRefreshPersons, onRefreshPersonDescriptors]);

    if (redirect !== undefined) {
        return <Redirect push={true} to={reduceQuery0(redirect.url, redirect.query)}/>;
    }

    const toggle = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    const toggleHeader = tab => {
        if (activeTabHeader !== tab) {
            setActiveTabHeader(tab);
        }
    }

    const onHandleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (isCanEdit) {
            if (name.substring(0, 5) === 'info.') {
                setCurrentWorkstation(prevValue => ({
                    ...prevValue
                    , info: {
                        ...prevValue.info
                        , [name.substring(5)]: value
                    }
                }));
            } else {
                setCurrentWorkstation(prevValue => ({
                    ...prevValue
                    , [name]: value
                }));
            }
        }
    }

    const loadOptionsPAL = (inputValue) =>
        new Promise((resolve) => {
            api.auth.requestTokenApiJson({
                request: `/api/person/find/${inputValue}`
            }).then((response) => {
                resolve(response.persons.map((i) => ({value: i.pal_id, id: i.id, label: `${i.name} в ${i.app_code}@${i.login}`})));
            });
        });

    const onHandlePALChange = (selectedOption) => {
        setSelectedPAL(prevState => ({
            ...prevState
            , pal_id: selectedOption !== null ? selectedOption.value : undefined
            , id: selectedOption !== null ? selectedOption.id : undefined
            , name: selectedOption !== null ? selectedOption.label : undefined
        }));
    };

    const onCancel = () => {
        setCanEdit(false);
        onRefreshWorkstationInfo(currentWorkstation.id);
    }

    const onSaveInfo = async () => {
        await api.auth.requestTokenApiJson({
            request: `/api/ws/update/${currentWorkstation.id}`
            , json: {
                name: currentWorkstation.name
                , comment: currentWorkstation.info?.comment
                , place: currentWorkstation.info?.place
                , time_line: parseInt(currentWorkstation.info?.time_line)
                , time_wait: parseInt(currentWorkstation.info?.time_wait)
                , similarity: parseFloat(currentWorkstation.info?.similarity ?? 0.7)
                , rc: parseFloat(currentWorkstation.info?.rc ?? 4.0)
                , save_metric: currentWorkstation.info?.save_metric
                , soft_block: currentWorkstation.info?.soft_block
                , save_full_image: currentWorkstation.info?.save_full_image
                , save_full_image_fail: currentWorkstation.info?.save_full_image_fail
                , save_full_image_success: currentWorkstation.info?.save_full_image_success
                , save_warp_fail: currentWorkstation.info?.save_warp_fail
                , save_warp_success: currentWorkstation.info?.save_warp_success
                , time_unblock: parseInt(currentWorkstation.info?.time_unblock)
                , agent: {
                    interval: parseInt(currentWorkstation.info?.agent_interval ?? 1000)
                    , mode: currentWorkstation.info?.agent_mode
                }
                , cam: {
                    interval: parseInt(currentWorkstation.info?.cam_interval ?? 1000)
                    , index: currentWorkstation.info?.cam_index
                    , mode: currentWorkstation.info?.cam_mode
                    , width: parseInt(currentWorkstation.info?.cam_width ?? 320)
                    , height: parseInt(currentWorkstation.info?.cam_height ?? 240)
                }
            }
        });

        setCanEdit(false);
        onRefreshWorkstationInfo(currentWorkstation.id);
    }

    const classesInputLeft = classNames('mb-1', {'border-primary': isCanEdit});
    const classesInputRight = classNames('border', 'rounded', {'border-primary': isCanEdit}, {'border-secondary': !isCanEdit});

    return (
        <Container fluid className={'p-2'}>
            <Helmet>
                <title>[CS24 Visionlabs CAP] - Рабочее место</title>
            </Helmet>
            <Card className={'border rounded shadow'}>
                <CardHeader className={'d-flex align-items-center'}>
                    <div className={'active-item p-2'} onClick={() => setRedirect(prevState => ({...prevState, 'url': `/v/ws`}))}>
                        <FontAwesomeIcon icon={faArrowLeft} title={'Вернуться к списку рабочих мест'}/>
                    </div>
                    <div className={'title mr-auto'}>Рабочее место</div>
                    <div className={'active-item p-2'} onClick={() => {
                        isCanEdit ? onSaveInfo() : setCanEdit(prevState => !prevState)
                    }}><FontAwesomeIcon icon={isCanEdit ? faSave : faEdit} color={'blue'}/>
                    </div>
                    <div className={'active-item p-2'} onClick={() => {
                        onRefreshWorkstationInfo(currentWorkstation.id)
                    }}>
                        <FontAwesomeIcon icon={faSyncAlt} title={'Обновить'}/>
                    </div>
                </CardHeader>
                <CardBody>
                    <Form>
                        <Row>
                            <Col xs={6}>
                                <Row className={'d-flex align-items-center'}>
                                    <Col xs={4} className={'d-flex justify-content-end'}>
                                        <Label for={'id'}>Идентификатор:</Label>
                                    </Col>
                                    <Col xs={8}>
                                        <Input name={'id'} id={'id'} placeholder={'Идентификатор'} value={currentWorkstation.id ?? ''} onChange={onHandleChange}
                                               readOnly className={classesInputLeft}
                                        />
                                    </Col>
                                </Row>
                                <Row className={'d-flex align-items-center'}>
                                    <Col xs={4} className={'d-flex justify-content-end'}>
                                        <Label for={'name'}>Имя компьютера:</Label>
                                    </Col>
                                    <Col xs={8}>
                                        <Input name={'name'} id={'name'} placeholder={'Имя компьютера'} value={currentWorkstation.name ?? ''}
                                               onChange={onHandleChange} readOnly className={classesInputLeft}/>
                                    </Col>
                                </Row>
                                <Row className={'d-flex align-items-center'}>
                                    <Col xs={4} className={'d-flex justify-content-end'}>
                                        <Label for={'place'}>Расположение:</Label>
                                    </Col>
                                    <Col xs={8}>
                                        <Input name={'info.place'} id={'place'} placeholder={'Расположение'} value={currentWorkstation.info?.place ?? ''}
                                               onChange={onHandleChange} className={classesInputLeft}/>
                                    </Col>
                                </Row>
                                <Row className={'d-flex align-items-center'}>
                                    <Col xs={4} className={'d-flex justify-content-end'}>
                                        <Label for={'comment'}>Описание:</Label>
                                    </Col>
                                    <Col xs={8}>
                                        <Input name={'info.comment'} id={'comment'} placeholder={'Описание'} value={currentWorkstation.info?.comment ?? ''}
                                               onChange={onHandleChange} className={classesInputLeft}/>
                                    </Col>
                                </Row>
                                <Row className={'d-flex align-items-center'}>
                                    <Col xs={4} className={'d-flex justify-content-end'}>
                                        <Label for={'vcam'}>Клиент камеры:</Label>
                                    </Col>
                                    <Col xs={8}>
                                        <Input name={'info.vcam'} id={'vcam'} value={`${currentWorkstation.info?.ver_cam ?? ''} (${Moment(currentWorkstation.info?.x_cam).format('YYYY-MM-DD HH:mm:ss')})`}
                                               readOnly onChange={onHandleChange} className={classesInputLeft}/>
                                    </Col>
                                </Row>
                                <Row className={'d-flex align-items-center'}>
                                    <Col xs={4} className={'d-flex justify-content-end'}>
                                        <Label for={'vagent'}>Клиент проверки:</Label>
                                    </Col>
                                    <Col xs={8}>
                                        <Input name={'info.vagent'} id={'vagent'} value={`${currentWorkstation.info?.ver_ag ?? ''} (${Moment(currentWorkstation.info?.x_ag).format('YYYY-MM-DD HH:mm:ss')})`}
                                               readOnly onChange={onHandleChange} className={classesInputLeft}/>
                                    </Col>
                                </Row>
                                <Row className={`align-items-center ${isCanEdit ? 'd-flex' : 'd-none'}`}>
                                    <Col xs={12} className={'d-flex justify-content-end'}>
                                        <Button className={'mx-1'} color={'primary'} onClick={() => onSaveInfo()}>Сохранить</Button>
                                        <Button className={'mx-1'} color={'secondary'} onClick={() => onCancel()}>Отменить</Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={6}>
                                <Row>
                                    <Col className={classesInputRight}>
                                        <Nav tabs className={'nav-bar mt-2'}>
                                            <NavItem>
                                                <NavLink className={classNames('cursor-pointer', {active: activeTabHeader === 'protocol'})} onClick={() => {
                                                    toggleHeader('protocol')
                                                }}>
                                                    Протокол
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classNames('cursor-pointer', {active: activeTabHeader === 'detector'})} onClick={() => {
                                                    toggleHeader('detector')
                                                }}>
                                                    Детектор
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classNames('cursor-pointer', {active: activeTabHeader === 'set_ca'})} onClick={() => {
                                                    toggleHeader('set_ca')
                                                }}>
                                                    Настройки агентов
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                        <TabContent activeTab={activeTabHeader} className={'mt-2'}>
                                            <TabPane tabId={'protocol'}>
                                                <Row form>
                                                    <FormGroup>
                                                        <FormGroup check>
                                                            <Input type="checkbox" id={'ism'} name={'info.save_metric'}
                                                                   checked={currentWorkstation.info?.save_metric ?? false}
                                                                   onChange={onHandleChange}/>
                                                            <Label check for={'ism'}>Отмечать в протоколе качество сравнения со всеми дескрипторами рабочего
                                                                места</Label>
                                                        </FormGroup>
                                                        <FormGroup check>
                                                            <Input type="checkbox" id={'isfi'} name={'info.save_full_image'}
                                                                   checked={currentWorkstation.info?.save_full_image ?? false}
                                                                   onChange={onHandleChange}/>
                                                            <Label check for={'isfi'}>Сохранять полный кадр при отсутствии лиц в кадре</Label>
                                                        </FormGroup>

                                                        <Label className={'text-success mt-2 mb-0'}>Совпадение лиц:</Label>
                                                        <FormGroup check>
                                                            <Input type="checkbox" id={'isws'} name={'info.save_warp_success'}
                                                                   checked={currentWorkstation.info?.save_warp_success ?? false}
                                                                   onChange={onHandleChange}/>
                                                            <Label check for={'isws'}>Сохранять изображения лиц при совпадении</Label>
                                                        </FormGroup>
                                                        <FormGroup check>
                                                            <Input type="checkbox" id={'isfis'} name={'info.save_full_image_success'}
                                                                   checked={currentWorkstation.info?.save_full_image_success ?? false}
                                                                   onChange={onHandleChange}/>
                                                            <Label check for={'isfis'}>Сохранять полный кадр при совпадении лиц</Label>
                                                        </FormGroup>

                                                        <Label className={'text-danger mt-2 mb-0'}>Отсутствие совпадения лиц:</Label>
                                                        <FormGroup check>
                                                            <Input type="checkbox" id={'iswf'} name={'info.save_warp_fail'}
                                                                   checked={currentWorkstation.info?.save_warp_fail ?? true}
                                                                   onChange={onHandleChange}/>
                                                            <Label check for={'iswf'}>Сохранять изображения лиц при отсутствии совпадений</Label>
                                                        </FormGroup>
                                                        <FormGroup check>
                                                            <Input type="checkbox" id={'isfif'} name={'info.save_full_image_fail'}
                                                                   checked={currentWorkstation.info?.save_full_image_fail ?? false}
                                                                   onChange={onHandleChange}/>
                                                            <Label check for={'isfif'}>Сохранять полный кадр при отсутствии совпадения лиц</Label>
                                                        </FormGroup>
                                                    </FormGroup>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId={'detector'}>
                                                <Row form>
                                                    <FormGroup>
                                                        <FormGroup className={'px-2'}>
                                                            <Label for={'similarity'}>Порог распознавания</Label>
                                                            <Input type={'number'} step={'0.01'} id={'similarity'} name={'info.similarity'}
                                                                   value={currentWorkstation.info?.similarity ?? 0.7}
                                                                   onChange={onHandleChange}/>
                                                        </FormGroup>
                                                        <FormGroup className={'px-2'}>
                                                            <Label for={'rc'}>Площадь распознавания %</Label>
                                                            <Input type={'number'} step={'0.01'} id={'rc'} name={'info.rc'}
                                                                   value={currentWorkstation.info?.rc ?? 4.0}
                                                                   onChange={onHandleChange}/>
                                                        </FormGroup>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <FormGroup className={'px-2'}>
                                                            <Label for={'time_wait'}>Шаг сглаживания, мсек</Label>
                                                            <Input type={'number'} id={'time_wait'} name={'info.time_wait'}
                                                                   value={currentWorkstation.info?.time_wait ?? 4000}
                                                                   onChange={onHandleChange}/>
                                                        </FormGroup>
                                                        <FormGroup className={'px-2'}>
                                                            <Label for={'time_line'}>Длительность контроля, мсек</Label>
                                                            <Input type={'number'} id={'time_line'} name={'info.time_line'}
                                                                   value={currentWorkstation.info?.time_line ?? 40000}
                                                                   onChange={onHandleChange}/>
                                                        </FormGroup>
                                                        <FormGroup className={'px-2'}>
                                                            <Label for={'time_unblock'}>Повышенное внимание, мсек</Label>
                                                            <Input type={'number'} id={'time_unblock'} name={'info.time_unblock'}
                                                                   value={currentWorkstation.info?.time_unblock ?? 3000}
                                                                   onChange={onHandleChange}/>
                                                        </FormGroup>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <FormGroup check>
                                                            <Input type="checkbox" id={'isb'} name={'info.soft_block'}
                                                                   checked={currentWorkstation.info?.soft_block ?? false}
                                                                   onChange={onHandleChange}/>
                                                            <Label check for={'isb'}>Режим мягкой блокировки</Label>
                                                        </FormGroup>
                                                    </FormGroup>
                                                </Row>

                                            </TabPane>
                                            <TabPane tabId={'set_ca'}>
                                                <Row form>
                                                    <FormGroup className={'px-2'}>
                                                        <Label for={'agent_interval'}>Интервал проверки агента, мсек</Label>
                                                        <Input type={'number'} id={'agent_interval'} name={'info.agent_interval'}
                                                               value={currentWorkstation.info?.agent_interval ?? 1000}
                                                               onChange={onHandleChange}/>
                                                    </FormGroup>
                                                    <FormGroup className={'px-2'}>
                                                        <Label for={'agent_mode'}>Строка настройки агента</Label>
                                                        <Input type={'text'} id={'agent_mode'} name={'info.agent_mode'}
                                                               value={currentWorkstation.info?.agent_mode ?? ''}
                                                               onChange={onHandleChange}/>
                                                    </FormGroup>
                                                </Row>
                                                <Row form className={'border-top'}>
                                                    <FormGroup className={'px-2'}>
                                                        <Label for={'cam_interval'}>Интервал проверки камеры, мсек</Label>
                                                        <Input type={'number'} id={'cam_interval'} name={'info.cam_interval'}
                                                               value={currentWorkstation.info?.cam_interval ?? 1000}
                                                               onChange={onHandleChange}/>
                                                    </FormGroup>
                                                    <FormGroup className={'px-2'}>
                                                        <Label for={'cam_index'}>Индекс камеры</Label>
                                                        <Input type={'text'} id={'cam_index'} name={'info.cam_index'}
                                                               value={currentWorkstation.info?.cam_index ?? 0}
                                                               onChange={onHandleChange}/>
                                                    </FormGroup>
                                                    <FormGroup className={'px-2'}>
                                                        <Label for={'cam_mode'}>Режим камеры</Label>
                                                        <Input type={'text'} id={'cam_mode'} name={'info.cam_mode'}
                                                               value={currentWorkstation.info?.cam_mode ?? ''}
                                                               onChange={onHandleChange}/>
                                                    </FormGroup>
                                                    <FormGroup className={'px-2'}>
                                                        <Label for={'cam_width'}>Ширина изображения камеры</Label>
                                                        <Input type={'text'} id={'cam_width'} name={'info.cam_width'}
                                                               value={currentWorkstation.info?.cam_width ?? 320}
                                                               onChange={onHandleChange}/>
                                                    </FormGroup>
                                                    <FormGroup className={'px-2'}>
                                                        <Label for={'cam_height'}>Высота изображения камеры</Label>
                                                        <Input type={'text'} id={'cam_height'} name={'info.cam_height'}
                                                               value={currentWorkstation.info?.cam_height ?? 240}
                                                               onChange={onHandleChange}/>
                                                    </FormGroup>
                                                </Row>
                                            </TabPane>
                                        </TabContent>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                    <Row className={'mt-2'}>
                        <Col>
                            <Nav tabs className={'nav-bar'}>
                                <NavItem>
                                    <NavLink className={classNames('cursor-pointer', {active: activeTab === 'person'})} onClick={() => {
                                        toggle('person');
                                    }}>
                                        <FontAwesomeIcon className={'mr-1'} icon={faUsers} color={'green'}/>Пользователи на рабочей станции
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classNames('cursor-pointer', {active: activeTab === 'journal'})} onClick={() => {
                                        toggle('journal');
                                    }}>
                                        <FontAwesomeIcon className={'mr-1'} icon={faClipboardList} color={'red'}/>Протокол использоваения
                                    </NavLink>
                                </NavItem>
                                {/*                                <NavItem>
                                    <NavLink className={classNames('cursor-pointer', {active: activeTab === 'stats'})} onClick={() => {
                                        toggle('stats');
                                    }}>
                                        <FontAwesomeIcon className={'mr-1'} icon={faChartPie} color={'blue'}/>Статистика использоваения
                                    </NavLink>
                                </NavItem>*/}
                            </Nav>
                            <TabContent activeTab={activeTab} className={'mt-2'}>
                                <TabPane tabId={'person'}>
                                    <Row>
                                        <Col xs={8}>
                                            <Table>
                                                <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Имя пользователя</th>
                                                    <th>Приложение</th>
                                                    <th>Логин</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {personLogins && personLogins.map((p, idx) => (
                                                    <tr key={idx}>
                                                        <th>{idx + 1}.</th>
                                                        <td><Link to={`/v/persons/${p.id}`}>{p.name}</Link></td>
                                                        <td>{p.code}</td>
                                                        <td>{p.login}</td>
                                                        <td>
                                                            <div className={'active-item p-2'} onClick={async () => {
                                                                await api.auth.requestTokenApiJson({
                                                                    request: `/api/app/dellink/${currentWorkstation.id}/${p.pal_id}`
                                                                });
                                                                onRefreshPersons(currentWorkstation.id);
                                                            }}>
                                                                <FontAwesomeIcon icon={faTrashAlt}/>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </Col>
                                        <Col xs={4}>
                                            <AsyncSelect
                                                cacheOptions
                                                defaultOptions
                                                value={{value: selectedPAL.pal_id, id: selectedPAL.id, label: selectedPAL.name}}
                                                loadOptions={loadOptionsPAL}
                                                onChange={onHandlePALChange}
                                            />
                                            <Button className={'my-1 w-100'} color={'primary'}
                                                    disabled={currentWorkstation.id === undefined || selectedPAL.pal_id === undefined}
                                                    onClick={async () => {
                                                        await api.auth.requestTokenApiJson({
                                                            request: `/api/app/link/${currentWorkstation.id}/${selectedPAL.pal_id}`
                                                        });
                                                        onRefreshPersons(currentWorkstation.id);
                                                    }}
                                            >Допустить логин</Button>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId={'journal'}>
                                    <WorkStationCamera2 id={currentWorkstation.ws_id} persons={personDescriptors}/>
                                </TabPane>
                                <TabPane tabId={'stats'}>
                                    <div className={'d-flex justify-content-end align-items-center'}>
                                        <RangeTimeSelect/>
                                    </div>
                                    {personDescriptors && Object.keys(personDescriptors).map((uid, key) =>
                                        <WorkStationStats key={key}
                                                          range={app.range}
                                                          workstation={currentWorkstation.ws_id}
                                                          user={{id: parseInt(uid), name: personDescriptors[uid].name}}/>
                                    )}
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Container>
    )
}

/*
                    Закладки:
                    Пользователи
                    Протокол с фильтрами:
                    - по периоду
                    - типу события
                    - граничным значенияем совпадений
                    Статистика по дескрипторам - какие чаще совпадают и с каким коэф
                    Индикатор:
                    Сколько времени с момента последнего события (5 минут без людей, 10 мин с аларма )
                    Средний коэф совпадения (текущий, за последнее время)
                    Графики под протокол:
*/