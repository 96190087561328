import {useDispatch, useSelector} from "react-redux";
import {changeRange} from "../actions/AppActions";
import {Col, Label, Row} from "reactstrap";
import React, {useState} from "react";
import moment from "moment";
import {addRange, PeriodSelect, subtractRange} from "./DateTimeSelect";
import {faCalendarAlt} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";

export const RandomRGBA = (vv = 255, aa) => {
    const o = Math.round, r = Math.random, s = vv;
    return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + (aa !== undefined ? aa : r().toFixed(1)) + ')';
};

export const GetFullDescription = (v, dv = '') => (v !== undefined && v !== null && v.code !== undefined) ? `[${v.code}] ${v.description}` : dv;

export const PrepareGroupOptionsForUsers = (users, dv) => {
    let groups = {};
    users.forEach(u => {
        const code = u.department?.id ?? -1;
        let g = groups[code];
        if (g === undefined) {
            groups[code] = {
                label: GetFullDescription(u.department, dv)
                , options: [
                    {value: u.id, label: u.name}
                ]
            }
        } else {
            g.options.push({value: u.id, label: u.name});
        }
    });

    return Object.keys(groups).map(k => groups[k]).sort((l, r) => l.code?.localeCompare(r.code));
};

export const FormRangeSelect = (props) => {
    const dispatch = useDispatch();
    const app = useSelector(state => state.app);
    const [isShowDayPicker, showDayPicker] = useState(false);

    return (
        <>
            <Row form>
                <Col md={3} className={'d-flex justify-content-end align-items-center'}>
                    <Label for="name">{props.title}</Label>
                </Col>
                <Col md={5} className={'d-flex justify-content-start align-items-center px-0'}>
                    <div className={'form-prefix-time'}>с</div>
                    <div className={'active-item p-1'} onClick={() => showDayPicker(prevState => !prevState)}>
                        <span className={'mr-2'}>{moment(app.range.from).format('L')}</span><FontAwesomeIcon icon={faCalendarAlt}/>
                    </div>
                    <div className={'active-item py-1 px-2 ml-2'} onClick={() => {
                        dispatch(changeRange(subtractRange(app.range)));
                    }}><FontAwesomeIcon icon={faChevronUp}/>
                    </div>
                </Col>
            </Row>
            <Row form className={'active-panel'}>
                <Col md={3} className={'d-flex justify-content-center align-items-center'}>
                    &nbsp;
                </Col>
                <Col md={5} className={'position-relative d-flex justify-content-start align-items-center px-0'}>
                    <div className={'form-prefix-time'}>по</div>
                    <div className={'active-item p-1'} onClick={() => showDayPicker(prevState => !prevState)}>
                        <span className={'mr-2'}>{moment(app.range.to).format('L')}</span><FontAwesomeIcon icon={faCalendarAlt}/>
                    </div>
                    <div className={'active-item py-1 px-2 ml-2'} onClick={() => {
                        dispatch(changeRange(addRange(app.range)));
                    }}><FontAwesomeIcon icon={faChevronDown}/>
                    </div>
                    <PeriodSelect
                        align={'center'}
                        range={{...app.range}}
                        isVisible={isShowDayPicker}
                        onCancel={() => showDayPicker(false)}
                        onSelect={(range) => {
                            showDayPicker(false);
                            dispatch(changeRange(range));
                        }}
                    />
                </Col>
            </Row>
        </>
    )
}