import React from "react";
import {useSelector} from "react-redux";
import {MenuPanel, MenuItem} from "../components";
import {faIndustry, faLaptopHouse, faUser, faUsersCog} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/fontawesome-free-regular";
import {faBug} from "@fortawesome/fontawesome-free-solid";

export const Menu = () => {
    const api = useSelector(state => state.api);

    const access_S = api.auth.isAcl(['s']);
    const access_A = api.auth.isAcl(['a']);

    const panel_cls = `mx-2 my-2 border rounded shadow`;
    const item_cls = `m-1 text-left border rounded`;

    return (
        <div className={'sidebar d-flex flex-column'}>
            <MenuPanel className={`${panel_cls} border-dark text-dark`} href={'/'} Title={'Главный экран'} Icon={faLaptopHouse}>
            </MenuPanel>
            <MenuPanel isVisible={access_S} className={`${panel_cls} border-success text-success`} Title={'Управление'} Icon={faEye}>
                <MenuItem className={`${item_cls}`} color={'success'} href={'/v/persons'}>Пользователи</MenuItem>
                <MenuItem className={`${item_cls}`} color={'success'} href={'/v/ws'}>Рабочие места</MenuItem>
                <MenuItem className={`${item_cls}`} color={'success'} href={'/apps'}>Приложения</MenuItem>
            </MenuPanel>
            <MenuPanel isVisible={access_A || access_S} className={`${panel_cls} border-primary bg-light`} Title={'Настройки'} Icon={faUsersCog}>
                <MenuItem isVisible={access_A} className={`${item_cls}`} color={'secondary'} href={'/s/company'}>
                    <FontAwesomeIcon className={'mr-2'} icon={faIndustry}/>Компании</MenuItem>
{/*
                <MenuItem isVisible={access_S} className={`${item_cls}`} color={'secondary'} href={'/s/company'}>
                    <FontAwesomeIcon className={'mr-2'} icon={faIndustry}/>Зональность</MenuItem>
*/}
                <MenuItem isVisible={access_S} className={`${item_cls}`} color={'secondary'} href={'/s/administrators'}>
                    <FontAwesomeIcon className={'mr-2'} icon={faUser}/>Администраторы</MenuItem>
                <MenuItem isVisible={access_S} className={`${item_cls}`} color={'secondary'} href={'/s/logs'}>
                    <FontAwesomeIcon className={'mr-2'} icon={faBug}/>Журнал</MenuItem>
            </MenuPanel>
        </div>
    );
};
